import {createEntityAdapter} from "@reduxjs/toolkit";
import { mosaicApi } from "./mosaicApiReducer";

import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "apiKeyReducer.js");


const apiKeyAdapter = createEntityAdapter({
  selectId: (apikey) => apikey._id,
  sortComparer: (a, b) => a.label.localeCompare(b.label),
});

export const apiKeyReducer = mosaicApi.injectEndpoints({
  endpoints: (build) => ({
    apiKeys: build.query({
      query: () => "/keyauth/",
      transformResponse: (entities) =>
          apiKeyAdapter.setMany(apiKeyAdapter.getInitialState(), entities),
      // FIXME: Can we possibly prodive the ids here and invalidate by id, and use a since arg here to know it will only return updated things?
      providesTags: ["Key"]
    }),


     newKey: build.mutation({
       query: ({keyRequest}) => ({
         url: `/keyauth/create`,
         method: "POST",
         body: keyRequest,
       }),
       // transformResponse: (baseQueryReturnValue, meta, arg) =>{
       //   dbg("transformResponse");
       //   dbg(baseQueryReturnValue);
       //   return apiKeyAdapter.setOne(apiKeyAdapter.getInitialState(), baseQueryReturnValue);
       //
       // }
       invalidatesTags: ["Key"]
     }),

    deleteKey: build.mutation( {
      query: ({keyRequest}) => ({
        url: '/keyauth/',
        method: 'DELETE',
        body: keyRequest,
      }),
      invalidatesTags: ["Key"]
    })

  }),
  //overRideExisting: false,
});

export const { 
    useApiKeysQuery, 
    useNewKeyMutation, 
    useDeleteKeyMutation 
} = apiKeyReducer;
