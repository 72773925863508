import {createSlice} from '@reduxjs/toolkit';
import createLogger from "../../utils/createLogger";

//import {Logger, LoggerFactory} from "lines-logger";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "redux/uploadCenter.js");


const getInitialState = () => {
    return {
        uploadProgress: {}
    };
}


const setTheProgress = (state, action) => {
    dbg(`setTheProgress: `, action.payload);
    state.uploadProgress[action.payload.uploadId] = {
        progress: action.payload.progress > 100 ? 100 : action.payload.progress,
        totalSize: action.payload.totalSize,
    };
}

const clearTheProgress = (state, action) => {
    if(action.payload.uploadId) {
        state.uploadProgress[action.payload.uploadId] = {};
    } else {
        state.uploadProgress = {};
    }

}

const timingSlice = createSlice({
    name: 'uploadCenter',
    initialState: getInitialState(),
    reducers: {
        setProgress: setTheProgress,
        clearProgress: clearTheProgress,
    }
});

const {actions, reducer} = timingSlice;

export const {
    setProgress,
    clearProgress,
} = actions;
export default reducer;

