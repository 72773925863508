import { getApiDomain } from "../../components/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import SuperTokens from "supertokens-auth-react";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "mosaicApiReducer.js");

// Here I'm creating an empty api service that we'll inject endpoints into
//  Example: `<api-domain><api><endpoint>` => where <endpoint> is an injection
//   See: /src/redux/MosaicApi/<endpoint>Reducer.js for code splitting routines


export const mosaicApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${getApiDomain()}/mosaic` }),
  endpoints: () => ({}),
  reducerPath: "mosaicApi",
  tagTypes: ["User", "Organization", "Activity", "Member", "Key"],
  keepUnusedDataFor: 240, // FIXME: I think we want this much bigger since almost everything uses things like user/org?
  // FIXME: But if we actually get the since/lastUpdated stuff in place, maybe we don't want a big value here?
  // FIXME: however, things like user and org objects rarely change, and mostly those updates are about getting new
  // FIXME: members, etc

  /* 
  focused: true,
  keepUnusedDataFor: 60,
  middlewareRegistered: true,
  online: true,
  reducerPath: "mosaicApi", // "reducerPath:" defaults to "api"
  refetchOnFocus: false,
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,


    These are all default parameters (and respective values) to this api cache
      Further tooling should be confirmed before implementing
      (i.e., no need to touch these, really, but intereseting to play with during dev)

      These can be fine tuned on both the reducer level
                      AND
      component level via the 2nd 'options' object argument to the react hooks.

      See the usage guide for RTKQuery here: https://redux-toolkit.js.org/rtk-query/overview
  */
});
