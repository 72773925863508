import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "modelMapReducer.js");


const modelAdapter = createEntityAdapter({
  selectId: (model) => model._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const addModels = (state, action) => {
        // transform array to an object
        if (isArray(action.payload)) {
            modelAdapter.setMany(state, action.payload);
        } else {
            modelAdapter.setOne(state, action.payload);
        }
}

const modelsMapSlice = createSlice({
    name: 'modelsMap',
    initialState: modelAdapter.getInitialState(),
    reducers: {
         deleteModelCacheItem: (state, action) => {
            modelAdapter.removeOne(state, action.payload.id);
        },
        clearModels: (state, action) => {
              modelAdapter.removeAll(state);
        },
        addModelToCache: (state, action) => { modelAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        // Anything that returns a Model should be caught here -- if it just returns an Model or [Model, ..],
        // addModels will take it.
        builder.addMatcher(neuralApi.endpoints.models.matchFulfilled, addModels);
        builder.addMatcher(neuralApi.endpoints.deleteModel.matchFulfilled,
            (state, action) =>
                modelAdapter.removeOne(state, action.payload._id)
        );
    }
});

export const selectModels = (state) => state.models.entities
export const selectModelIds = (state) => state.models.ids

export const selectModelList = createSelector([selectModels, selectModelIds],
    (models, ids) =>  ids.map(id => models[id]))



export const selectModelById = (id) => createSelector(
    selectModels,
    items => items[id]
)

export const selectModelsByProject = (id) => createSelector(
    selectModelList,
    items =>  items.filter(item => item.project_id === id))

export const selectModelLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.models.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const { deleteModelCacheItem, clearModels, addModelToCache } = modelsMapSlice.actions;

export default modelsMapSlice.reducer;