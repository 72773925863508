
import React from 'react';
import createLogger from "../utils/createLogger";
import {isProduction} from "./config";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "ErrorBoundary.js");

// FIXME: doesn't seem like there is a getDerivedStateFromError equiv in functional components?


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

//   static getDerivedStateFromError(error) {
//     // Whenever a child component throws an error, update state
//     return { hasError: true };
//   }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // If there is an error, force full page refresh
      if(isProduction())
        window.location.href = '/';
    }

    return this.props.children;
  }
}

export default ErrorBoundary;