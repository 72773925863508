import "core-js/proposals/promise-with-resolvers";
import React from "react";
import { Form } from "react-router-dom";
import imagePlaceholder from "../../assets/pictures/image-placeholder.png";
import featrixSdk from "../../assets/pictures/featrix-sdk.jpg";
import featrixFeeds from "../../assets/pictures/featrix-feeds.jpg";
import featrixHaystack from "../../assets/pictures/featrix-haystack.jpg";
import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { HttpStatusCode } from "axios";
import { redirectToAuth } from "supertokens-auth-react";

import { projectReducer, useAddNewProjectMutation } from "../../redux/NeuralApi/projectReducer";
import createLogger from "../../utils/createLogger";
import { useNavigate } from "react-router-dom";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, `NewProjectView.js`);

export const NameNewProjectDialog = ({ open, onOpenChange, projectType }) => {
    const [projectName, setProjectName] = useState("");
    // const navigate = useNavigate();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const openChangeHandler = (newOpenState) => {
        if (!newOpenState) {
            setProjectName(null);
            setError(null);
            // navigate("."); // force a reload of API keys
        }
        onOpenChange(newOpenState);
    };


    const onLabelChanged = (text) => {
        var t = text.trimStart();       // Do not trim everything.
        setProjectName(t);
        setError(null);
    };

    const [addNewProject, { isLoading }] = useAddNewProjectMutation();

    const handleSubmit = () => {
        console.log("projectType = ", projectType);
        addNewProject({name: projectName.trim(), type: projectType})
            .unwrap()
            .then((payload) => {
                // dbg("this is what success looks like:");
                // dbg("payload ==> ", payload);
                onOpenChange(false);

                // open the project page.
                navigate(`/projects/${payload._id}/overview`);
            })
            .catch((error) => {
                logError(error);
                if (error.status === HttpStatusCode.Unauthorized) {
                    redirectToAuth({ redirectBack: true });
                    return {};
                } else if (error.status === HttpStatusCode.Conflict) {
                    var trimmedName = projectName.trim();
                    if (trimmedName !== projectName) {
                        setProjectName(trimmedName);
                    }
                    return setError("Project by this name already exists");
                }
            });
    };

    var displayType = "AI SDK";
    if (projectType === 'explorer') {
        displayType = "Explorer"
    }
    else if (projectType === 'haystack') {
        displayType = "Haystack"
    }

    return (
        <Dialog.Root open={open} onOpenChange={openChangeHandler}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent new-api-key">
                    <Dialog.Title className="DialogTitle">
                        New {displayType} Project Name
                    </Dialog.Title>
                    <Dialog.Description className="DialogDescription">
                        Provide a unique name for your {displayType} project.
                    </Dialog.Description>
                    <div>
                        <label>Name</label>
                        <div>
                            <input
                                name="label"
                                type="text"
                                className="api-key-label full-width"
                                placeholder=""
                                value={projectName}
                                onChange={(e) => onLabelChanged(e.target.value)}
                                required 
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        handleSubmit();
                                    }
                                  }}
                            />
                            <strong className="input-error">{error}</strong>
                        </div>
                        <div className="bottom-button-bar flex justify-end g-12">
                            <button
                                type="submit"
                                style={{ marginRight: "16px" }}
                                onClick={handleSubmit}
                                disabled={!projectName}>
                                Create
                            </button>
                            <Dialog.Close asChild>
                                <button>Cancel</button>
                            </Dialog.Close>
                        </div>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};
