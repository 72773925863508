import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {mosaicApi} from "./MosaicApi/mosaicApiReducer";
import {neuralApi} from "./NeuralApi/neuralApiReducer";
import {userReducer} from "./MosaicApi/userReducer";
import usersReducer from "./MosaicApi/usersMapReducer";
import {organizationReducer} from "./MosaicApi/organizationReducer";
import organizationMapReducer from "./MosaicApi/organizationMapReducer";
import {apiKeyReducer} from "./MosaicApi/apiKeyReducer";
import apiKeyMapReducer from "./MosaicApi/apiKeyMapReducer";
import {projectReducer} from "./NeuralApi/projectReducer";
import projectsMapReducer from "./NeuralApi/projectMapReducer";
import {activityReducer} from "./MosaicApi/activityReducer";
import activityMapReducer from "./MosaicApi/activityMapReducer";
import {jobReducer} from "./NeuralApi/jobReducer";
import jobMapsReducer from "./NeuralApi/jobsMapReducer";
import {uploadReducer} from "./NeuralApi/uploadReducer";
import uploadsMapReducer from "./NeuralApi/uploadMapReducer";
import {embeddingReducer} from "./NeuralApi/embeddingReducer";
import embeddingMapReducer from "./NeuralApi/embeddingMapReducer";
import {cloudDbReducer} from "./MosaicApi/cloudDbReducer";
import cloudDbMapReducer from "./MosaicApi/cloudDbMapReducer";
import {feedReducer} from "./NeuralApi/feedReducer";
import feedMapReducer from "./NeuralApi/feedsMapReducer";
import timingReducer from "./MosaicApi/timing";
import uploadCenterReducer from "./NeuralApi/uploadCenter";
import adminReducer from "./MosaicApi/admin";
import {modelReducer} from "./NeuralApi/modelReducer";
import modelsMapReducer from "./NeuralApi/modelMapReducer";
import {predictionReducer} from "./NeuralApi/predictionReducer";
import predictionsMapReducer from "./NeuralApi/predictionMapReducer";

import createLogger from "../utils/createLogger";
import { sassFalse } from "sass";

const DEBUG = sassFalse;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "mosaicStore.js");


/* 
    CURRENTLY: There are two api base paths(this redux store manages both):

                                ${apiDomain}/mosaic
                                ${apiDomain}/neural

    - See: /redux/MosaicApi & /redux/NeuralApi for their respective reducers' logic

      You can always view your server's terminal output while navigating to determine
      the api you're trying to work with: i.e. "GET /neural/../.." & "POST /mosaic/../.."
  */

export const mosaicStore = configureStore({
    reducer: {
        usersRTK: userReducer,
        users: usersReducer,
        organizationsRTQ: organizationReducer,
        organizations: organizationMapReducer,
        apiKeysRTQ: apiKeyReducer,
        apiKeys: apiKeyMapReducer,
        activityRTQ: activityReducer,
        activity: activityMapReducer,
        projectsRTQ: projectReducer,
        projects: projectsMapReducer,
        jobsRTQ: jobReducer,
        jobs: jobMapsReducer,
        uploadsRTQ: uploadReducer,
        uploads: uploadsMapReducer,
        modelsRTQ: modelReducer,
        models: modelsMapReducer,
        embeddings: embeddingMapReducer,
        embeddingsRTQ: embeddingReducer,
        cloudDbs: cloudDbMapReducer,
        cloudDbsRTQ: cloudDbReducer,
        feedsRTQ: feedReducer,
        feeds: feedMapReducer,
        predictionRTQ: predictionReducer,
        predictions: predictionsMapReducer,
        timings: timingReducer,
        uploadCenter: uploadCenterReducer,
        admin: adminReducer,
        [mosaicApi.reducerPath]: mosaicApi.reducer,
        [neuralApi.reducerPath]: neuralApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([mosaicApi.middleware, neuralApi.middleware]),
});

setupListeners(mosaicStore.dispatch);
