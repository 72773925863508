/*
 * Usage:

import createLogger from '../utils/createLogger';

const DEBUG = true;
const debug = createLogger(DEBUG, `FILENAME`);

 */

import Moment from 'moment';
import {isProduction} from "../components/config";
import * as Sentry from "@sentry/react";
import {LoggerFactory} from "lines-logger";


const GLOBAL_LOGGING = !isProduction();
const fd = process.env.FEATRIX_LOG_DATES;
const ft = process.env.FEATRIX_LOG_TAGS;
// Log dates if there is no env variable, otherwise only log if set to yes/true
const log_dates = fd ? ["yes", "YES", "true", "TRUE"].includes(fd) : true;
const log_tags = ft ? ["yes", "YES", "true", "TRUE"].includes(ft) : true;

const fmt = 'HH:mm:ss';
const tag_prefix = ` ---------------------------------------------------------------- `;

function _noop() {
}

let loggerFactory;

if(GLOBAL_LOGGING) {
    loggerFactory = new LoggerFactory();
} else {
    loggerFactory = new LoggerFactory('disable');
}

const _logger = (tag) => (...args) => {
    const tagPrefix = log_tags ? `${tag} ` : '';
    let prefix = log_dates ? `${Moment().format(fmt)}: ${tagPrefix} ` : `${tagPrefix}`;

    if (typeof args[0] === 'string') {
        args[0] = `${prefix}${args[0]}`;
    } else {
        args.unshift(`${prefix}`);
    }

    [...args].forEach((arg) => console.log(arg));
}

const _log_error = (tag) => function () {
    [...arguments].forEach((arg) => {
      console.error(arg);
      if(!GLOBAL_LOGGING) Sentry.captureMessage(arg);
    });
};


const _create = (active, tag) => {
    let _active = GLOBAL_LOGGING && active;
    if (_active) {
        // console.log(`Logging enabled for ${tag}`);
    }

    return {
        enter: _active ? (method) => console.log(Moment().format(fmt) + tag_prefix + tag + ' ::: > ' + method) : _noop,
        dbg: _active ? _logger(tag) : _noop,
        leave: _active ? (method) => console.log(Moment().format(fmt) + tag_prefix + tag + ' ::: < ' + method) : _noop,
        logError: _log_error(tag),

    }
};
export default _create;
export const lf = loggerFactory;
