import { TopBarMenu } from "./TopBarMenu";
import logo from "../assets/pictures/featrix-logo-transparent-376x96.png";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "Banner.js");


export const Banner = ({ isLogin = false }) => (
    <>
        {isLogin ?
            <header>
                <nav id="banner" className="text-info">
                    <a href="/auth">
                        <img
                            id="banner-logo"
                            src={logo}
                            alt="Featrix"
                        />
                    </a>
                </nav>
            </header>

            :

            <header className="banner-container">
                <nav id="banner" className="text-info">
                    <a href="/auth">
                        <img
                            id="banner-logo"
                            src={logo}
                            alt="Featrix"
                        />
                    </a>
                </nav>
                <TopBarMenu />
            </header>
        }

    </>

);
