import {neuralApi} from "../NeuralApi/neuralApiReducer";
import {mosaicApi} from "./mosaicApiReducer";

import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import {selectApiKeyIds, selectApiKeys} from "./apiKeyMapReducer";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "organizationMapReducer.js");


const organizationsAdapter = createEntityAdapter({
  selectId: (organization) => organization._id,
  sortComparer: (a, b) => a.name <= b.name
});

const setWsJWT = (state, action) => {
    state.ws_jwt = action.payload;
}

const organizationsMapSlice = createSlice({
    name: 'organizationsMap',
    initialState: () => ({'ws_jwt': null, ...organizationsAdapter.getInitialState() }),
    reducers: {
        deleteOrganizationCacheItem: (state, action) => {
            organizationsAdapter.removeOne(state, action.payload.id);
        },
        clearOrganizations: (state, action) => {
              organizationsAdapter.removeAll(state);
        },
        addOrganizationToCache: (state, action) => { organizationsAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.getUserOrganizations.matchFulfilled,
            (state, action) =>
                    organizationsAdapter.setMany(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.getWsAuth.matchFulfilled, setWsJWT);
    }
});

export const selectOrganizations = (state) => state.organizations.entities
export const selectOrganizationIds = (state) => state.organizations.ids

export const selectWsAuth = (state) => state.organizations.ws_jwt;

export const selectOrganizationList = createSelector(
    [selectOrganizations, selectOrganizationIds],
    (keys, ids) => ids.map(id => keys[id]));

export const selectOrganizationById = (id) => createSelector(
    selectOrganizations,
    items => items[id]
)
export const { deleteOrganizationCacheItem, clearOrganizations, addOrganizationToCache } = organizationsMapSlice.actions;
export const selectOrganizationsLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.organizations.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export default organizationsMapSlice.reducer;