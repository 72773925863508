
export const jobType = {
    JOB_TYPE_ES_TRAIN_MORE : "embedding-space-train-more",
    JOB_TYPE_ES_CREATE : "embedding-space-create",
    JOB_TYPE_ES_CREATE_PROJECTION : "embedding-space-projection-create",
    JOB_TYPE_ES_WAIT_TO_TRAIN : "embedding-space-waiting-for-training",
    JOB_TYPE_ES_CREATE_FROM_DS : "embedding-space-create-from-data-space",
    JOB_TYPE_ES_CREATE_DB : "embedding-space-create-database",
    JOB_TYPE_DB_CLUSTER : "vector-db-cluster",
    JOB_TYPE_DB_NN_QUERY : "vector-db-nn-query",
    JOB_TYPE_MODEL_CREATE : "model-create",
    JOB_TYPE_MODEL_PREDICTION : "model-prediction",
    JOB_TYPE_MODEL_GUARDRAILS : "model-guardrails",
    JOB_TYPE_AUTOJOIN_MAIN       : "autojoin-main",
    JOB_TYPE_AUTOJOIN_DETECT : "autojoin-detect",
    JOB_TYPE_AUTOJOIN_PROJECTION : "autojoin-projection",
    JOB_TYPE_ENCODE_RECORDS : "encode-records",
    JOB_TYPE_TEST_CASE : "test-case",
    JOB_TYPE_PROCESS_UPLOAD_DATA : "process-uploaded-data",
    JOB_TYPE_PROCESS_SMART_ENRICHMENT : "process-smart-enrichment",
    JOB_TYPE_DETECT_ENCODERS     : "detect-encoders",
    JOB_TYPE_ES_DISTANCE : "embedding-space-distance",
    JOB_TYPE_HAYSTACK_PROCESSING : "haystack-processing",
    JOB_TYPE_HAYSTACK_WAIT_PREP : "haystack-wait-prep",
    JOB_TYPE_HAYSTACK_RUN_MODEL : "haystack-run-model",
    JOB_TYPE_DB_CONVERT_DUMP : "cloud-db-convert-dump",


    // These are never a job.job_type
    CHAINED_JOB_TYPE_NNF: "chained-new-neural-function",
    CHAINED_JOB_TYPE_NNF_WAIT_ES: "chained-new-neural-function-wait-es",
    CHAINED_JOB_TYPE_NEW_EXPLORER: "chained-new-explorer",
    CHAINED_JOB_TYPE_HAYSTACK_PREDICTOR: "chained-haystack-predictor",
}
