import {createEntityAdapter} from "@reduxjs/toolkit";
import {mosaicApi} from "./mosaicApiReducer";

import createLogger from "../../utils/createLogger";
import moment from "moment/moment";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "cloudDbReducer.js");

let sinceLastCloudDbRequest;

export const cloudDbReducer = mosaicApi.injectEndpoints({
    endpoints: (build) => ({
        cloudDbs: build.query({
            queryFn: async (arg, queryApi, extraOptions, fetchWithBQ) => {
                let url = `/cloud_db/${arg || ""}`;
                if (arg === undefined) {
                    if(sinceLastCloudDbRequest !== undefined)
                    {
                        dbg(`Using sinceLastUploadRequest ${sinceLastCloudDbRequest}`);
                        url += `?since=${sinceLastCloudDbRequest}`
                    }
                }
                const result = await fetchWithBQ({
                    url: url,
                });
                if (!result.error) {
                    dbg("setting sinceLastCloudDbRequest ")
                    result.data.forEach(item => {
                        if(!sinceLastCloudDbRequest || moment.utc(item.updated_at) > moment.utc(sinceLastCloudDbRequest)) {
                            dbg(`Setting sinceLastUploadRequest to ${item.updated_at} becaause of ${item._id}`);
                            sinceLastCloudDbRequest = moment.utc(item.updated_at).toISOString();
                        }
                    })

                }
                // console.log("@@@@ RESULT = ", result);
                return result;
            },
            providesTags: ['CloudDB'],
            /*
            cloudDbs: build.query({
            query: () => "/cloud_db/",
            // FIXME: Can we possibly provide the ids here and invalidate by id, and use a since arg here to know it will only return updated things?
            providesTags: ["CloudDB"]
        }),
                 */

        }),



        connectionString: build.query({
            query: (cloudDbId) => `/cloud_db/connection_string/${cloudDbId}`
        }),

        addCloudDb: build.mutation({
            query: (cloudDbRequest) => ({
                url: `/cloud_db/`,
                method: "POST",
                body: cloudDbRequest,
            }),
        }),

        activateCloudDb: build.mutation({
            query: ({id}) => ({
                url: `/cloud_db/activate/${id}`,
                method: "PUT",
            }),
        }),

        deactivateCloudDb: build.mutation({
            query: ({id}) => ({
                url: `/cloud_db/deactivate/${id}`,
                method: "PUT",
            }),
        }),
        editCloudDb: build.mutation({
            query: (body) => {
                dbg(`Sending request editCloudDb upload type:`, body);
                return {
                    url: `/cloud_db/edit`,
                    method: "PUT",
                    body,
                }
            },
        }),
        deleteCloudDb: build.mutation({
            query: ({cloudDbRequest}) => ({
                url: '/cloud_db/',
                method: 'DELETE',
                body: cloudDbRequest,
            }),
        }),
        snapshotNow: build.mutation({
            query: (cloudDbId) => ({
                url: `/cloud_db/${cloudDbId}/snapshot_now`,
                method: 'POST',
            }),
        })

    }),
    //overRideExisting: false,
});

export const {
    useCloudDbsQuery,
    useConnectionStringQuery,
    useAddCloudDbMutation,
    useDeleteCloudDbMutation,
    useActivateCloudDbMutation,
    useDeactivateCloudDbMutation,
    useSnapshotNowMutation,
    useEditCloudDbMutation,
} = cloudDbReducer;
