import classNames from "classnames";
import { CopyToClipboardIcon } from "./icons";
import { DefaultThemedCodeHighlighter } from "./CodeHighlighter";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const { dbg, enter, leave, logError } = createLogger(DEBUG, "clipboard.js");


export const CopyToClipboardButton = ({ text, className, ...rest }) => (
    <CopyToClipboardIcon
        className={classNames("clickable", className)}
        title="Copy to clipboard"
        onClick={() => copyToClipboard(text)}
        {...rest}
    />
);

const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
};

export const InlineCopyableText = ({ children }) => {
    return (
        <span className="inline-copyable">
            {children}{" "}
            <CopyToClipboardIcon
                className="clickable"
                title="Copy to clipboard"
                onClick={() => copyToClipboard(children)}
            />
        </span>
    );
};

export const CopyableSourceCode = ({ code, language, props }) => {
    return (
        <div className="copyable-source-code-container">
            <CopyToClipboardIcon
                className="floating-copy-icon clickable"
                title="Copy to clipboard"
                height="20px"
                width="20px"
                onClick={() => copyToClipboard(code)}
            />
            <DefaultThemedCodeHighlighter
                className="copyableCode"
                language={language ?? "python"}
                {...props}
            >
                {code}
            </DefaultThemedCodeHighlighter>
        </div>
    );
};
