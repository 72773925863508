import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "uploadMapReducer.js");

const uploadAdapter = createEntityAdapter({
  selectId: (upload) => upload._id,
  sortComparer: (a, b) => a.filename.localeCompare(b.filename),
});

const addUploads = (state, action) => {
        // transform array to an object
        if (isArray(action.payload)) {
            dbg(" addUploads: setMany", action.payload);
            dbg(action.payload);
            uploadAdapter.setMany(state, action.payload);
        } else {
            dbg("addUploads: setOne", action.payload);
            dbg(action.payload);
            uploadAdapter.setOne(state, action.payload);
        }
}

const uploadsMapSlice = createSlice({
    name: 'uploadsMap',
    initialState: uploadAdapter.getInitialState(),
    reducers: {
         deleteUploadCacheItem: (state, action) => {
            uploadAdapter.removeOne(state, action.payload.id);
        },
        clearUploads: (state, action) => {
              uploadAdapter.removeAll(state);
        },
        addUploadToCache: (state, action) => {
             uploadAdapter.addOne(state, action.payload);
        }
    },
    extraReducers: builder => {
        // Anything that returns an Upload should be caught here -- if it just returns an Upload or [Upload, ..],
        // addUploads will take it.
        builder.addMatcher(neuralApi.endpoints.uploads.matchFulfilled, addUploads);
        builder.addMatcher(neuralApi.endpoints.newUpload.matchFulfilled, addUploads);
        builder.addMatcher(neuralApi.endpoints.delayedUpload.matchFulfilled, addUploads);
        builder.addMatcher(neuralApi.endpoints.multipartComplete.matchFulfilled, addUploads);
        builder.addMatcher(neuralApi.endpoints.partUpload.matchFulfilled, addUploads);
    }
});


export const selectUploads = (state) => state.uploads.entities
export const selectUploadIds = (state) => state.uploads.ids

export const selectUploadsList = (state) => createSelector(
    [selectUploads, selectUploadIds],
    (uploads, ids) => ids.map(id => uploads[id]));

// FIXME: Move all the selectors to a singel file and delete these three as they are dups
export const selectProjects = (state) => state.projects.entities
export const selectProjectIds = (state) => state.projects.ids
//export const selectProjectList = (state) => state.projects.ids.map(id => state.projects.entities[id])
export const selectProjectList = createSelector(
    [selectProjects, selectProjectIds],
    (projects, ids) =>  ids.map(id => projects.entities[id]))


export const selectUploadById = (id) => createSelector(
    selectUploads,
    items => items[id]
)

export const selectAssociatedUploadIds = (projectId) => createSelector(
    selectProjects,
    (projects) => projects[projectId].associated_uploads.map(assoc => assoc.upload_id)
);

// This will always be the smallest list in the project collection
export const selectUploadsByProject = (projectId) =>
    createSelector([selectAssociatedUploadIds(projectId), selectUploads],
        (uploadIds = [], uploads) => {
            return Object.values(uploads).filter(up => uploadIds.includes(up._id));
        });

export const selectUploadLatestUpdate = (state) => {
    let latest = null;
    dbg("Checking for latest update", state.uploads.entities);
    Object.values(state.uploads.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const selectIsProjectReady = (projectId) =>
    createSelector(
        selectUploadsByProject,
        (items) => items.map(up => up.ready_for_training).every(val => val === true));


export const {
    deleteUploadCacheItem,
    clearUploads,
    addUploadToCache
} = uploadsMapSlice.actions;

export default uploadsMapSlice.reducer;