import setErrorAction from "react";
import createLogger from "../utils/createLogger";
import {isProduction} from "../components/config";
const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "errors.js");


export function registerHandlers(store) {
    // unhandled promise rejections
    window.addEventListener("error", (event) => {
        logError(event.message);
        if(isProduction())
            window.location.href = '/';
    });

    // exceptions in sync code that is outside of the react purview
    window.addEventListener("unhandledrejection", (event) => {
        logError(event.reason.message);
        if(isProduction())
            window.location.href = '/';
    });
}

