import * as React from "react";
import classNames from "classnames";
import embeddingSpaceIcon from "../assets/pictures/icon_embedding_space.svg";
import tableFileIcon from "../assets/pictures/table-file-icon.png";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "Icons.js");


export const DataSourceIcon = ({className, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="icon_datasource_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 29.37 28.23"
        className={classNames("icon", "datasource-icon", className)}
        {...rest}
    >
      <path
          className="filled"
          d="M19.45 19.6v-5.51l-4.77-2.76-4.77 2.76v5.51l4.77 2.75z"
      />
      <path
          className="unfilled"
          d="M28.99 25.05v-5.51l-14.31-8.21-4.77 2.76v5.51l14.31 8.2z"
      />
      <path
          className="unfilled"
          d="M19.45 19.6V3.19L14.68.43 9.91 3.19V19.6l4.77 2.75z"
      />
      <path
          className="unfilled"
          d="M19.45 19.6v-5.51l-4.77-2.76-14.3 8.21v5.51l4.76 2.75z"
      />
    </svg>
);

export const ProjectIcon = ({className, colorOveride=null, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="icon_project_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 60 60"
        className={classNames("icon", "project-icon ", className)}
        style={colorOveride != null ? {stroke: colorOveride} : {}}
        {...rest}
    >
      <path d="M30.09 25.28H13.12L4.63 39.97l8.49 14.7h16.97l8.49-14.7z"/>
      <path d="M46.88 15.83H29.91l-8.49 14.7 8.49 14.69h16.97l8.49-14.69z"/>
      <path d="M30.09 5.34H13.12L4.63 20.03l8.49 14.7h16.97l8.49-14.7z"/>
    </svg>
);

export const TableFileIcon = ({alt, ...rest}) => {
  return (
      <img
          className="table-file-icon"
          src={tableFileIcon}
          style={{paddingLeft: "4px", width: "32px", height: "26px", paddingRight: "4px"}}
          alt={alt}
          {...rest}
      />
  );
};
export const EmbeddingSpaceIcon = ({alt, ...rest}) => {
  return (
      <img
          className="embedding-space-icon"
          src={embeddingSpaceIcon}
          alt={alt}
          {...rest}
      />
  );
};

export const CopyToClipboardIcon = ({title, className, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="icon_copy_paste_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 40.5 40.5"
        className={classNames("icon", "clipboard-icon", className)}
        {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path d="M5.5 31H1V1h30v4.5" className="unfilled"/>
      <path d="M9.5 9.5h30v30h-30z" className="unfilled"/>
    </svg>
);

export const TeamIcon = ({className, pathClassName, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="icon_team_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 -2 45 45"
        className={classNames("icon", "team-icon", className)}
        {...rest}
    >
      <path
          className={pathClassName ? pathClassName : "filled"}
          d="M 7.234808 9.962799 C 6.550306 10.826786 6.178356 11.896997 6.179487 12.999277 C 6.179487 15.718506 8.380769 17.919783 11.1 17.919783 C 13.81923 17.919783 16.020514 15.718506 16.020514 12.999277 C 16.020514 11.853312 15.625577 10.797989 14.965192 9.962799 C 12.496267 8.908367 9.703732 8.908367 7.234808 9.962799 M 11.1 32.875557 C 15.191795 32.875557 18.836859 30.972094 21.200001 28.006836 C 19.438974 22.83383 15.580256 19.247028 11.1 19.247028 C 6.619744 19.247028 2.761026 22.827349 1 28.000366 C 3.369616 30.972094 7.008205 32.875557 11.1 32.875557"
      />
      <path
          className={pathClassName ? pathClassName : "filled"}
          d="M 30.173077 9.954971 C 29.495352 10.810402 29.127085 11.870016 29.128204 12.961382 C 29.128204 15.653688 31.307692 17.833172 34 17.833172 C 36.692307 17.833172 38.871796 15.653688 38.871796 12.961382 C 38.871796 11.826765 38.48077 10.781891 37.826923 9.954971 C 35.382442 8.910976 32.617558 8.910976 30.173077 9.954971 M 34 32.640869 C 38.051281 32.640869 41.660255 30.75625 44 27.820351 C 42.256409 22.698563 38.435898 19.147276 34 19.147276 C 29.564102 19.147276 25.743589 22.692146 24 27.813946 C 26.346153 30.75625 29.948717 32.640869 34 32.640869"/>
      <path
          className={pathClassName ? pathClassName : "filled"}
          d="M 18.590786 6.773495 C 17.953836 7.577454 17.607725 8.57333 17.608776 9.599026 C 17.608776 12.129372 19.657141 14.177729 22.187479 14.177729 C 24.71781 14.177729 26.766182 12.129372 26.766182 9.599026 C 26.766182 8.532673 26.398682 7.550655 25.784172 6.773495 C 23.486748 5.792309 20.888203 5.792309 18.590786 6.773495 M 25.320274 23.045517 C 24.943378 21.938381 28.499315 19.456181 28.005527 18.570097 C 26.352415 15.603642 25.397633 15.412771 22.187479 15.412771 C 19.435925 15.412771 19.117298 15.304247 17.264515 17.675013 C 16.310053 18.896301 18.108376 20.428568 19.949768 22.150433 C 22.154776 24.915722 19.886887 22.224295 22.635021 23.045517"
    />

    </svg>
);

export const UserIcon = ({className, pathClassName, ...rest}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="icon_user_svg__Layer_1"
        x={0}
        y={0}
        viewBox="0 0 31.21 36.61"
        className={classNames("icon", "user-icon", className)}
        {...rest}
    >
    <path
          className={pathClassName ? pathClassName : "filled"}
          d="M9.63 1.22A7.54 7.54 0 0 0 8 5.91c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6c0-1.77-.61-3.4-1.63-4.69a15.2 15.2 0 0 0-11.94 0M15.6 36.61c6.32 0 11.95-2.94 15.6-7.52-2.72-7.99-8.68-13.53-15.6-13.53S2.72 21.09 0 29.08c3.66 4.59 9.28 7.53 15.6 7.53"
    />

    </svg>
);

export const MongoIcon = ({className, ...rest}) => (
    <svg height="25" viewBox="8.738 -5.03622834 17.45992422 39.40619484" width="25" style={{marginRight: "8px"}}
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="m15.9.087.854 1.604c.192.296.4.558.645.802a22.406 22.406 0 0 1 2.004 2.266c1.447 1.9 2.423 4.01 3.12 6.292.418 1.394.645 2.824.662 4.27.07 4.323-1.412 8.035-4.4 11.12a12.7 12.7 0 0 1 -1.57 1.342c-.296 0-.436-.227-.558-.436a3.589 3.589 0 0 1 -.436-1.255c-.105-.523-.174-1.046-.14-1.586v-.244c-.024-.052-.285-24.052-.181-24.175z"
          fill="#599636"/>
      <path
          d="m15.9.034c-.035-.07-.07-.017-.105.017.017.35-.105.662-.296.96-.21.296-.488.523-.767.767-1.55 1.342-2.77 2.963-3.747 4.776-1.3 2.44-1.97 5.055-2.16 7.808-.087.993.314 4.497.627 5.508.854 2.684 2.388 4.933 4.375 6.885.488.47 1.01.906 1.55 1.325.157 0 .174-.14.21-.244a4.78 4.78 0 0 0 .157-.68l.35-2.614z"
          fill="#6cac48"/>
      <path
          d="m16.754 28.845c.035-.4.227-.732.436-1.063-.21-.087-.366-.26-.488-.453a3.235 3.235 0 0 1 -.26-.575c-.244-.732-.296-1.5-.366-2.248v-.453c-.087.07-.105.662-.105.75a17.37 17.37 0 0 1 -.314 2.353c-.052.314-.087.627-.28.906 0 .035 0 .07.017.122.314.924.4 1.865.453 2.824v.35c0 .418-.017.33.33.47.14.052.296.07.436.174.105 0 .122-.087.122-.157l-.052-.575v-1.604c-.017-.28.035-.558.07-.82z"
          fill="#c2bfbf"/>
    </svg>
);
