import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import {selectModelIds, selectModelList, selectModels} from "./modelMapReducer";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "embeddingMapReducer.js");



const embeddingsAdapter = createEntityAdapter({
  selectId: (embedding) => embedding._id,
  sortComparer: (a, b) => a.name <= b.name
});


const embeddingsMapSlice = createSlice({
    name: 'embeddingsMap',
    initialState: embeddingsAdapter.getInitialState(),
    reducers: {
         deleteEmbeddingCacheItem: (state, action) => {
            // console.log("STATE = ", state);
            // console.log("ACTION = ", action);
            embeddingsAdapter.removeOne(state, action.payload._id);
        },
        clearEmbeddings: (state, action) => {
              embeddingsAdapter.removeAll(state);
        },
        addEmbeddingToCache: (state, action) => { embeddingsAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(neuralApi.endpoints.getAllEmbeddingSpaces.matchFulfilled,
            (state, action) =>
                    embeddingsAdapter.setMany(state, action.payload)
        );
        // Other matchers? Project.embeddings?
    }
});

export const selectEmbeddings = (state) => state.embeddings.entities
export const selectEmbeddingIds = (state) => state.embeddings.ids

export const selectEmbeddingList = createSelector(
    [selectEmbeddings, selectEmbeddingIds],
    (embeddings, ids) =>  ids.map(id => embeddings[id]))


export const selectEmbeddingById = (id) => createSelector(
    selectEmbeddings,
    items => items[id]
)

export const selectEmbeddingsByProject = (id) => createSelector(
    selectEmbeddingList,
    items =>  items.filter(item => item.project_id === id))


export const selectMostRecentActiveEmbedding = createSelector(
    selectEmbeddingList,
    embeddings => {
        let list = embeddings.sort((a, b) => moment(a.updated_at) - moment(b.updated_at))
        return list.length ? list[list.length - 1] : null;
    }
)

export const selectEmbeddingsLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.embeddings.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}


export const { deleteEmbeddingCacheItem, clearEmbeddings, addEmbeddingToCache } = embeddingsMapSlice.actions;

export default embeddingsMapSlice.reducer;