import CryptoJS from "crypto-js";
import SparkMD5 from "spark-md5";
import createLogger from "./createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, `hashes.js`);

const CHUNK_SIZE = 1024;

export const calculateHash = (file, short=false) => {
    return new Promise(async (resolve) => {
        if (file.size < CHUNK_SIZE * 2 || !short) {
            const spark = new SparkMD5.ArrayBuffer();
            const reader = file.stream().getReader();
            let result;

            dbg("starting to calc full md5")
            while (!result || !result.done) {
                result = await reader.read();
                //dbg("block to md5")
                if (!result.done) {
                    spark.append(result.value);
                }
            }
            dbg("Done calculating full md5")
            resolve(spark.end());
        }
        dbg("Calculating short hash of file");
        let firstChunk = file.slice(0, CHUNK_SIZE),
            lastChunk = file.slice(file.size - CHUNK_SIZE);

        dbg(`Using chunk 1 0-${CHUNK_SIZE} and chunk 2 ${file.size - CHUNK_SIZE}-${file.size}`);

        const reader1 = new FileReader();
        reader1.onloadend = (e) => {
            dbg("reader1 onLoadedEnd");
            if (e.target.readyState === FileReader.DONE) {
                dbg("Got back first chunk read");
                const reader2 = new FileReader();
                reader2.onloadend = (ee) => {
                    dbg("reader2 onLoadedEnd");
                    let tmp = new Uint8Array(e.target.result.byteLength + ee.target.result.byteLength);
                    tmp.set(new Uint8Array(e.target.result), 0);
                    tmp.set(new Uint8Array(ee.target.result), e.target.result.byteLength);
                    const wordArray = CryptoJS.lib.WordArray.create(tmp.buffer);
                    dbg("WE have a workArray -->", wordArray);
                    dbg(`The MD5 is returning`, CryptoJS.MD5(wordArray).toString());
                    resolve(CryptoJS.MD5(wordArray).toString());
                }
                dbg(`Calling reader2 on lastChunk ${lastChunk.size}`);
                reader2.readAsArrayBuffer(lastChunk);
            }
        };
        dbg(`Calling reader1 on firstChunk ${firstChunk.size}`);
        reader1.readAsArrayBuffer(firstChunk);
    });
}

export const calculateHashBuffer = (buffer) => {
    let spark = new SparkMD5.ArrayBuffer();
    spark.append(buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength));
    return spark.end()
}