import {neuralApi} from "../NeuralApi/neuralApiReducer";
import {mosaicApi} from "./mosaicApiReducer";

import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "apiKeyMapReducer.js");


const apiKeysAdapter = createEntityAdapter({
  selectId: (apiKey) => apiKey._id,
  sortComparer: (a, b) => a.label <= b.label
});


const apiKeysMapSlice = createSlice({
    name: 'apiKeysMap',
    initialState: apiKeysAdapter.getInitialState(),
    reducers: {
        deleteApiKeyCacheItem: (state, action) => {
            apiKeysAdapter.removeOne(state, action.payload.id);
        },
        clearApiKeys: (state, action) => {
              apiKeysAdapter.removeAll(state);
        },
        addApiKeyToCache: (state, action) => { apiKeysAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.apiKeys.matchFulfilled,
            (state, action) =>
                    apiKeysAdapter.setMany(state, action.payload)
        );
    }
});

export const selectApiKeys = (state) => state.apiKeys.entities
export const selectApiKeyIds = (state) => state.apiKeys.ids

export const selectApiKeyList = createSelector(
    [selectApiKeys, selectApiKeyIds],
    (keys, ids) => ids.map(id => keys[id]));


export const selectApiKeyById = (id) => createSelector(
    selectApiKeys,
    items => items[id]
)

export const selectApiKeysByUser = (id) => createSelector(
    selectApiKeyList,
    items => items.filter(key => key.created_by === id))

export const { deleteApiKeyCacheItem, clearApiKeys, addApiKeyToCache } = apiKeysMapSlice.actions;
export const selectApiKeyLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.apiKeys.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export default apiKeysMapSlice.reducer;