import { mosaicApi } from "./mosaicApiReducer";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "activityReducer.js");


export const activityReducer = mosaicApi.injectEndpoints({
  endpoints: (build) => ({
    activities: build.query({
      query: () => "/activity/",
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map((activity) => ({ type: "Activity", activity })),
              "Activity",
            ]
          : ["Activity"],
    }),

    getProjectActivities: build.query({
      query: (project_id) => `/activity/project_id/${project_id}`,
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map((activity) => ({ type: "Activity", activity })),
              "Activity",
            ]
          : ["Activity"],
    }),
  }),

  overrideExisting: false,
});

export const { useActivitiesQuery } = activityReducer;
