import { getApiDomain } from "../../components/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "neuralApiReducer.js");

export const neuralApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${getApiDomain()}/neural` }),
  endpoints: () => ({}),
  reducerPath: "neuralApi",
  tagTypes: [
     "Project",
     "Job",
     "Model",
     "Field",
     "Uploads",
     "Info",
     "Meta",
     "Embedding",
     "Distance",
     "Histogram",
     "Database",
     "Feed",
   ],
});
