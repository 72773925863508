import * as Tooltip from "@radix-ui/react-tooltip";
import moment from "moment";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "RelativeDate.js");


export const RelativeDate = ({ when, prefix = "", nobr_ok=true }) => {
    const whenMoment = new moment(when);

    return (
        <Tooltip.Provider>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    {nobr_ok ? 
                        <nobr>
                            {prefix}
                            {whenMoment.fromNow()}
                        </nobr>
                        :
                        <>
                            {prefix}
                            {whenMoment.fromNow()}

                        </>
                    }
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                        {whenMoment.format("LLL")}
                        <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
