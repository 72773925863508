import {useSelector} from "react-redux";
import {selectProjectJobs} from "../../redux/NeuralApi/jobsMapReducer";
import {RelativeDate} from "../RelativeDate";
import moment from "moment/moment";
import React, {useState} from "react";
import {HaystackProcessingMessage} from "./HaystackJobMonitor";
import {jobType} from "../../utils/jobs";
import {Spinner} from "../Spinner";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const { dbg} = createLogger(DEBUG, `HaystackResultSetPicker.js`);

export const HaystackRunModelProcessingMessage = ({ orig_msg="", msg, color="white", doc }) => {

    const [showToast, setShowToast] = useState(true);

    // const handleShowToast = () => {
    //     setShowToast(true);
    //     setTimeout(() => {
    //     setShowToast(false);
    //     }, 3000); // Auto close toast after 3 seconds
    // };

    // setShowToast(true);


    return (<>
        <div className="nf-container">
            <div className="nf-topline">
                <div className="nf-top-flex-container">
                    <div className="nf-flex-col nf-left">
                        <div className="column" style={{ flex: "0 0 36px" }}>
                            <Spinner />
                        </div>
                    </div>
                    <div className="nf-flex-col nf-center">
                        <span title={orig_msg} style={{ color: color }}>
                            {msg}
                        </span>
                    </div>
                </div>
            </div>

            <div className="nf-main-content-full" style={{ paddingLeft: "32px" }}>
                <div>
                    <p>
                        {doc}
                    </p>

                    <p className="mt-12">Haystack will continue processing even if you lose your connection or accidentally close this window.</p>

                </div>
            </div>
            <div className="nf-bottom-line">
            </div>
        </div>
    </>);
}


export const HaystackRunModelJobMonitor = ({ project, upload, job }) => {
    // we get passed an upload and/or a job -- if upload and !job, we watch the upload for it being finished processing
    // -- once it is done, the job will get kicked off by the caller and we will have the job to watch instead.
    const uploadJob = useSelector(state => upload ? Object.values(state.jobs.entities).find(job => job.request_args?.upload_id === upload?._id && job.job_type === jobType.JOB_TYPE_PROCESS_UPLOAD_DATA) : null);
    const color = "#F0B27A";
    const procMsg = job ? `Haystack is running the model on file ${upload.filename}...` : `Haystack is processing ${upload.filename}, this will take a minute or so`;
    const doc = job ? `Haystack is running the uploaded file ${upload.filename} through the model` : `Haystack is processing your upload file ${upload.filename}, and will run it through the model as soon as it is done`;

    if(upload && !job) {
        return <HaystackRunModelProcessingMessage doc={doc}
                                                  msg={uploadJob?.incremental_status?.message || procMsg}
                                                  color={color}/>
    } else if(job?.finished === false) {
        return <HaystackRunModelProcessingMessage doc={doc} msg={job?.incremental_status?.message || procMsg} color={color}/>
    } else if(job?.finished === true) {
        return <div>
            Why are you still here, the job is done dude
        </div>
   } else {
        dbg("Some kind of error on haystck run model -- ", upload, job);
        return <div>Some kind of error</div>
    }
}