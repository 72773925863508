import { ClickToEdit } from "../ClickToEdit";
import {selectCurrentOrg} from "../../redux/MosaicApi/admin";
import createLogger from '../../utils/createLogger';
import {useUpdateOrganizationMutation} from "../../redux/MosaicApi/organizationReducer";
import {useSelector} from "react-redux";

const DEBUG = true;
const { dbg, enter, leave, logError } = createLogger(DEBUG, `OrganizationSettingsTab.js`);

export const OrganizationSettingsTab = () => {
    const organization = useSelector(selectCurrentOrg);

    const [
        updateOrganization,
        { isError, isSuccess, isLoading, isUninitialized, originalArgs, status }
    ] = useUpdateOrganizationMutation();

    return (
        <section className="resource resource-members">
            <span className="resource-section-label">Settings</span>
            <div style={{ marginTop: "16px" }}>

                <ClickToEdit
                    label="Team Name:"
                    text={organization.name}
                    onUpdate={
                        (name) => {
                            let updatedOrg = { ...organization };
                            updatedOrg.name = name.trim();
                            updateOrganization({ organization: updatedOrg })
                        }
                    }
                />
            </div>
        </section>
    );
};
