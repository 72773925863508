import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "feedsMapReducer.js");



const feedsAdapter = createEntityAdapter({
  selectId: (feed) => feed._id,
  sortComparer: (a, b) => a.feed_name <= b.feed_name
});


const feedsMapSlice = createSlice({
    name: 'feedsMap',
    initialState: feedsAdapter.getInitialState(),
    reducers: {
         deleteFeedCacheItem: (state, action) => {
            feedsAdapter.removeOne(state, action.payload.id);
        },
        clearFeeds: (state, action) => {
              feedsAdapter.removeAll(state);
        },
        addFeedToCache: (state, action) => { feedsAdapter.addOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(neuralApi.endpoints.createFeed.matchFulfilled,
            (state, action) =>
                    feedsAdapter.addOne(state, action.payload)
        );
         builder.addMatcher(neuralApi.endpoints.feeds.matchFulfilled,
            (state, action) =>
                    feedsAdapter.setMany(state, action.payload)
        );
        // Other matchers? Project.feeds?
    }
});


export const selectFeeds = (state) => state.feeds.entities
export const selectFeedIds = (state) => state.feeds.ids


export const selectFeedList = (state) => createSelector(
    [selectFeeds, selectFeedIds],
    (feeds, ids) => ids.map(id => feeds[id]));


export const selectFeedById = (id) => createSelector(
    selectFeeds,
    items => items[id]
)

export const selectFeedsLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.feeds.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const { deleteFeedCacheItem, clearFeeds, addFeedToCache } = feedsMapSlice.actions;

export default feedsMapSlice.reducer;