import {neuralApi} from "../NeuralApi/neuralApiReducer";
import {mosaicApi} from "./mosaicApiReducer";

import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "cloudDbMapReducer.js");


const cloudDbsAdapter = createEntityAdapter({
  selectId: (apiKey) => apiKey._id,
  sortComparer: (a, b) => a.name <= b.name
});


const cloudDbSlice = createSlice({
    name: 'cloudDbMap',
    initialState: cloudDbsAdapter.getInitialState(),
    reducers: {
        deleteCloudDbCacheItem: (state, action) => {
            cloudDbsAdapter.removeOne(state, action.payload.id);
        },
        clearCloudDb: (state, action) => {
              cloudDbsAdapter.removeAll(state);
        },
        addCloudDbToCache: (state, action) => { cloudDbsAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.cloudDbs.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.setMany(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.addCloudDb.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.setOne(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.editCloudDb.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.setOne(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.activateCloudDb.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.setOne(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.deactivateCloudDb.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.setOne(state, action.payload)
        );
        builder.addMatcher(mosaicApi.endpoints.deleteCloudDb.matchFulfilled,
            (state, action) =>
                    cloudDbsAdapter.removeOne(state, action.payload)
        );
    }
});

export const selectCloudDbs = (state) => state.cloudDbs.entities
export const selectCloudDbsIds = (state) => state.cloudDbs.ids

export const selectCloudDbByProjectId = (projectId) => createSelector(
selectCloudDbs,
    (cloudDbs) => {
        dbg(`Clouddbs in select by project ${projectId}`, cloudDbs);
    // We give them the list for a project if they give us a project id, otherwsie we give them all of the cloud dbs
        return Object.values(cloudDbs).filter((cloudDb) => projectId ?cloudDb.project_id === projectId : true);
    }
);
export const selectCloudDbsLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.cloudDbs.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const { addCloudDbToCache, clearCloudDb, deleteCloudDbCacheItem } = cloudDbSlice.actions;

export default cloudDbSlice.reducer;