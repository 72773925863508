import { neuralApi } from "./neuralApiReducer";
import { getApiDomain } from "../../components/config";
import moment from "moment/moment";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "jobReducer.js");


let sinceLastJobsRequest;

export const jobReducer = neuralApi.injectEndpoints({
    endpoints: (build) => ({
        jobs: build.query({
            queryFn: async (arg, queryApi, extraOptions, fetchWithBQ) => {
                // Use the global variable in request

                let url = `/job/`;
                if (sinceLastJobsRequest !== undefined) {
                    url += `?since=${sinceLastJobsRequest}`
                }
                const result = await fetchWithBQ({
                    url: url,
                });

                 if (!result.error) {
                    // dbg("setting uploadLastTime ")
                    result.data.forEach(item => {
                        if(!sinceLastJobsRequest || moment.utc(item.updated_at) > moment.utc(sinceLastJobsRequest)) {
                            sinceLastJobsRequest = moment.utc(item.updated_at).toISOString();
                        }
                    })
                } else {
                    dbg("Error with jobs get");
                }
                // dbg("Jobs Results:", Object.keys(result.data).length);
                // dbg(result);
                // Handle the response data update accordingly
                return result;
            },
            pollingInterval: 10 * 1000, // 10 sec to start
            providesTags: ['Jobs'],
        }),

        checkJobStreaming: build.query({
            query: (_id) => `/job/${_id}`,
            async onCacheEntryAdded(arg, { updateCacheData, cacheDataLoaded, cacheEntryRemoved }) {
                const url = `${getApiDomain()}/api/neural/jobs/ws/${arg}`.replace('https', 'wss').replace('http', 'ws');
                dbg(`In onCacheEntryAdded -- using url ${url}`)
                const ws = new WebSocket(url);
                try {
                    dbg("ws connect called -- waiting for initial cache data loaded to be done")
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded
                    dbg("cachedata loaded -- adding a listener now...")
                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    const listener = (event) => {
                        const job = JSON.parse(event.data)
                        dbg("Job on stream");
                        dbg(job);
                        updateCacheData((draft) => {
                            draft.push(job)
                        })
                    }

                    ws.addEventListener('message', listener)
                } catch {
                    dbg("in ws catch clause -- ")
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                dbg("waiting on cacheEntryRemoved now")
                await cacheEntryRemoved
                dbg("Closing ws...")
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                ws.close()
            }
        }),
        getJobsById: build.query({
            query: (_id) => `/job/${_id}`,
            providesTags: (result, error, _id) => [{ type: "Job", _id }],
        }),
    }),
    // options
});

export const { 
    useJobsQuery, 
    useCheckJobStreamingQuery, 
    useGetJobsByIdQuery,
} = jobReducer;