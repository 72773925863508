import {useApiKeysQuery} from "../redux/MosaicApi/apiKeyReducer";
import {useGetCurrentUserQuery} from "../redux/MosaicApi/userReducer";
import {hotjar} from "react-hotjar";
import {useEffect, useRef, useState} from 'react';
import {
    useGetUserCurrentOrganizationQuery,
    useMembersQuery,
} from "../redux/MosaicApi/organizationReducer";
import {useUploadsQuery} from "../redux/NeuralApi/uploadReducer"
import {useJobsQuery} from "../redux/NeuralApi/jobReducer";
import {useProjectsQuery} from "../redux/NeuralApi/projectReducer"

import {useActivitiesQuery} from "../redux/MosaicApi/activityReducer"
import {useFeedsQuery} from "../redux/NeuralApi/feedReducer"
import {useCloudDbsQuery} from "../redux/MosaicApi/cloudDbReducer";

import {deleteJobCacheItem, addJobToCache, clearJobs, selectJobsLatestUpdate} from "../redux/NeuralApi/jobsMapReducer";
import {
    deleteModelCacheItem,
    clearModels,
    addModelToCache,
    selectModelLatestUpdate
} from "../redux/NeuralApi/modelMapReducer";
import {
    deleteEmbeddingCacheItem,
    clearEmbeddings,
    addEmbeddingToCache,
    selectEmbeddingsLatestUpdate
} from "../redux/NeuralApi/embeddingMapReducer";
import {
    deleteProjectCacheItem,
    addProjectToCache,
    clearProjects,
    selectProjectLatestUpdate
} from "../redux/NeuralApi/projectMapReducer";
import {deleteUploadCacheItem, clearUploads, addUploadToCache} from "../redux/NeuralApi/uploadMapReducer";
import {
    deleteFeedCacheItem,
    clearFeeds,
    addFeedToCache,
    selectFeedsLatestUpdate
} from "../redux/NeuralApi/feedsMapReducer";
import {
    deleteApiKeyCacheItem,
    clearApiKeys,
    addApiKeyToCache,
    selectApiKeyLatestUpdate
} from "../redux/MosaicApi/apiKeyMapReducer";
import {
    deleteUserCacheItem,
    clearUsers,
    addUserToCache,
    selectUsersLatestUpdate
} from "../redux/MosaicApi/usersMapReducer";
import {
    selectPendingDeleteIds,
    clearDeleted,
    clearActivities,
    addActivityToCache, selectActiviesLatestUpdate
} from "../redux/MosaicApi/activityMapReducer";
import {useGetWsAuthQuery} from "../redux/MosaicApi/organizationReducer";
import {
    addOrganizationToCache,
    deleteOrganizationCacheItem, selectOrganizationsLatestUpdate,
    selectWsAuth
} from "../redux/MosaicApi/organizationMapReducer";
import {
    fetchTimings,
    clearAll,
    selectGlobalTimings
} from '../redux/MosaicApi/timing'
import {useDispatch, useSelector} from "react-redux";
import createLogger from "../utils/createLogger";
import {useModelsQuery} from "../redux/NeuralApi/modelReducer";
import {useGetAllEmbeddingSpacesQuery} from "../redux/NeuralApi/embeddingReducer";
import {getApiDomain, isProduction} from "./config";
import {
    addPredictionToCache,
    deletePredictionCacheItem,
    selectPredictionLatestUpdate
} from "../redux/NeuralApi/predictionMapReducer";
import {selectUploadLatestUpdate} from "../redux/NeuralApi/uploadMapReducer";
import {selectCloudDbsLatestUpdate} from "../redux/MosaicApi/cloudDbMapReducer";

const DEBUG = false;
const {dbg, logError} = createLogger(DEBUG, "BaseInformation.js");


const recheckTimer = 5000;

export const BaseInformation = ({children}) => {
    const ws = useRef(null);
    const dispatch = useDispatch()

    const [currentOrganizationId, setCurrentOrganizationId] = useState(null);

    const jobTime = useSelector(selectJobsLatestUpdate);
    const uploadTime = useSelector(selectUploadLatestUpdate);

    const projectTime = useSelector(selectProjectLatestUpdate);
    const embeddingTime = useSelector(selectEmbeddingsLatestUpdate);
    const modelTime = useSelector(selectModelLatestUpdate);
    const feedTime = useSelector(selectFeedsLatestUpdate);
    const apikeyTime = useSelector(selectApiKeyLatestUpdate);
    const activityTime = useSelector(selectActiviesLatestUpdate);
    const memberTime = useSelector(selectUsersLatestUpdate);
    const organizationTime = useSelector(selectOrganizationsLatestUpdate);
    const cloudDbTime = useSelector(selectCloudDbsLatestUpdate);
    //const predictionTime = useSelector(selectPredictionLatestUpdate);
    const deletePendings = useSelector(selectPendingDeleteIds);
    const globalTimings = useSelector(selectGlobalTimings);

    // FIXME: We need to just kill the RTK interfaces/queries, everything outside of this
    // FIXME: uses the reducerMap stuff that uses id maps and has caching intelligence.

    // Subscription to these here for global objects, so they are never unsubscribed.
    const {data: user} = useGetCurrentUserQuery();
    const {isLoading: orgsLoading, refetch: orgkeyRefetch} = useGetUserCurrentOrganizationQuery();
    const {isLoading: membersLoading, refetch: membersRefetch} = useMembersQuery();
    const {isLoading: apikeysLoading, refetch: apikeyRefetch} = useApiKeysQuery();
    const {isLoading: uploadsLoading, refetch: uploadRefetch} = useUploadsQuery();
    const {isLoading: projectsLoading, refetch: projectRefetch} = useProjectsQuery();
    const {isLoading: jobsLoading, refetch: jobRefetch} = useJobsQuery();
    const {isLoading: modelsLoading, refetch: modelRefetch} = useModelsQuery();
    const {isLoading: embeddingsLoading, refetch: embeddingRefetch} = useGetAllEmbeddingSpacesQuery();
    const {isLoading: activitiesLoading, refetch: activityRefetch} = useActivitiesQuery();
    const {isLoading: feedsLoading, refetch: feedRefetch} = useFeedsQuery();
    const {isLoading: cloudDbLoading, refetch: cloudDbRefetch} = useCloudDbsQuery();
    const {data: wsAuth, isLoading: wsAuthLoading, refetch: wsAuthRefetch} = useGetWsAuthQuery();
    const wsAuthInfo = useSelector(selectWsAuth);


    // How to delete or insert something into a cache
    const actionMap = {
        upload: {delete: deleteUploadCacheItem, upsert: addUploadToCache},
        jobs: {delete: deleteJobCacheItem, upsert: addJobToCache},
        projects: {delete: deleteProjectCacheItem, upsert: addProjectToCache},
        models: {delete: deleteModelCacheItem, upsert: addModelToCache},
        embedding_space: {delete: deleteEmbeddingCacheItem, upsert: addEmbeddingToCache},
        activity: {delete: null, upsert: addActivityToCache},
        apikey: {delete: deleteApiKeyCacheItem, upsert: addApiKeyToCache},
        feed: {delete: deleteFeedCacheItem, upsert: addFeedToCache},
        //invitations: {delete: deleteInvitationCacheItem, upsert: xxxx},
        organizations: {delete: deleteOrganizationCacheItem, upsert: addOrganizationToCache},
        predictions: {delete: deletePredictionCacheItem, upsert: addPredictionToCache},
        users: {delete: deleteUserCacheItem, upsert: addUserToCache},
        
    }
    // A method to create a well formed lookup object for the useEffect below
    const lookupCreator = (time, fetch, isLoading) => {
        return {time, fetch, isLoading}
    };
    const connectors = {
        job_meta: lookupCreator(jobTime, jobRefetch, jobsLoading),
        project: lookupCreator(projectTime, projectRefetch, projectsLoading),
        upload: lookupCreator(uploadTime, uploadRefetch, uploadsLoading),
        members: lookupCreator(memberTime, membersRefetch, membersLoading),
        api_key: lookupCreator(apikeyTime, apikeyRefetch, apikeysLoading),
        //prediction: lookupCreator(predictionTime, predictionRefetch, predictionsLoading),
        model: lookupCreator(modelTime, modelRefetch, modelsLoading),
        embedding_space: lookupCreator(embeddingTime, embeddingRefetch, embeddingsLoading),
        activity: lookupCreator(activityTime, activityRefetch, activitiesLoading),
        feed: lookupCreator(feedTime, feedRefetch, feedsLoading),
        organization: lookupCreator(organizationTime, orgkeyRefetch, orgsLoading),
        cloud_db: lookupCreator(cloudDbTime, cloudDbRefetch, cloudDbLoading)
    }

    if (!currentOrganizationId && user?.current_organization_id) {
        setCurrentOrganizationId(user.current_organization_id);
    }
    useEffect(() => {
        if (user && isProduction()) {
            hotjar.identify(user._id, {email: user.email, organization: user.current_organization_id});
        }
    }, [user]);
    /*
     * By default, this useEffect should be the only thing in this file that is doing anything -- and it should
     * probably be in redux.  But we had the hacked timing stuff here, and put the websocket stuff here so we can
     * fall back on the timing stuff for now if the websocket connection craps out -- the Digital Ocean LB isnt'
     * supposed to allow for wss -> ws fallback but it seems to?
     */
    useEffect(() => {
        return;
        if (!wsAuthInfo) return;

        (async function () {
            const url = getApiDomain().replace('http', 'ws')
            ws.current = new WebSocket(`${url}/mosaic/organizations/ws/connect`);

            ws.current.onopen = () => {
                console.log('WebSocket is connected now.');
                ws.current.send(JSON.stringify({'jwt': wsAuthInfo.jwt}));
            }
            // handle incoming messages
            ws.current.onmessage = (event) => {
                // process the incoming message.
                const obj = JSON.parse(event.data);
                if (obj.operationType === 'delete') {
                    if (obj.collection !== '') {
                        const act = actionMap[obj.collection]?.delete;
                        if (act) {
                            dbg(`Delete Operation on ${obj.collection} with id ${obj._id}`);
                            dispatch(act({id: obj._id}));
                        } else {
                            dbg(`No delete action defined for ${obj.collection}`);
                        }
                    }
                } else {
                    const act = actionMap[obj.collection]?.upsert;
                    if (act) {
                        dbg(`${obj.operationType} Operation on ${obj.collection} with id ${obj.fullDocument._id}`);
                        dispatch(act(obj.fullDocument));
                    } else {
                        dbg(`No upsert action defined for ${obj.collection}`);
                    }
                }
            }

            // handle connection close events
            ws.current.onclose = (event) => {
                console.log('WebSocket is closed now.', event.reason);
            };

            // handle errored
            ws.current.onerror = (err) => {
                console.error('WebSocket encountered error: ', err.message, 'Closing socket.');
                ws.current.close();
            };
            return (() => {
                if (ws.current) {
                    ws.current.close();
                }
            })
        })();
    }, [currentOrganizationId, wsAuthInfo]);


    useEffect(() => {
        dbg(`In ChangeOrg useEffect -- ${currentOrganizationId} and ${user?.current_organization_id}`)
        if (currentOrganizationId && user?.current_organization_id) {
            if (currentOrganizationId !== user.current_organization_id) {
                // Clear our caches and force a re-fetch.  Part of the clear is to reset our timings so we get
                // everything on the re-fetch.
                setCurrentOrganizationId(user.current_organization_id);
                [
                    clearAll, clearActivities, clearUsers, clearJobs, clearProjects, clearModels,
                    clearApiKeys, clearFeeds, clearUploads, clearEmbeddings,
                ].forEach((action) => {
                    dispatch(action());
                });
                dispatch(clearDeleted({'id': 'all'}));
                [
                    jobRefetch, modelRefetch, feedRefetch, activityRefetch, projectRefetch,
                    apikeyRefetch, membersRefetch, embeddingRefetch, uploadRefetch
                ].forEach(reFetch => {
                    reFetch()
                });
            }
        }
    }, [user, currentOrganizationId]);
    

    useEffect(() => {
        dbg(` USE EFFECT: upload (${uploadsLoading}, ${uploadTime}) jobs (${jobsLoading}, ${jobTime}`);

        // let request = {};
        // Object.keys(connectors).forEach(key => {
        //     request[key] = connectors[key].time;
        // })
        // dbg(`useEffect created request to:`, request);
        const interval = setInterval(() => {
            dbg("Interval: Fetching timings with request package:", globalTimings);
            if (!ws.current) {

                dispatch(fetchTimings(globalTimings))
                    .unwrap()
                    .then((payload) => {
                        ////  DEBUG
                        let str = "";
                        Object.keys(payload).forEach(value => {
                            if (payload[value]) str += `${value}: true, `
                        })
                        if (str.length) dbg(`Interval: Timings calling for updates: ${str}`);
                        else dbg(`Interval: No Collections Out of Date`)

                        Object.keys(connectors).forEach(key => {
                            if (payload[key] && !connectors[key].isLoading) {
                                connectors[key].fetch();
                            }
                        });

                    })
                    .catch(error => {
                        dbg("Interval: c'est la vie?", error);
                        logError(error);
                    });

                /*
                 * Anytime we get a deletion event in the activity stream, the activity reducer keeps the dead id in
                 * state -- we pull it out here and clean it out of the appropriate reducer cache via an action.
                 * Note that we don't do this to the RTQ cache -- but we don't use that once a record lands there
                 * -- all the code uses the xxxMapReducer entity objects.
                 *
                 * This can all go away once we get a websocket -> mongo change stream hooked up.
                 *
                 * This isn't required when we are actively using the websocket.
                 */
                Object.keys(deletePendings).forEach(key => {
                    deletePendings[key].forEach((id) => {
                        // again, if we can get consistent on naming, this all gets simpler 
                        const lookup = {
                            'embedding': deleteEmbeddingCacheItem,
                            'model': deleteModelCacheItem,
                            'upload': deleteUploadCacheItem,
                            'project': deleteProjectCacheItem,
                            'user': deleteUserCacheItem,
                            'apikey': deleteApiKeyCacheItem,
                            'feed': deleteFeedCacheItem,
                        };

                        let payload = {id};
                        const tkey = key.replace('DeleteIds', '')
                        const clear = {id: tkey};
                        const deleteCacheItem = lookup[key];

                        if (deleteCacheItem) {
                            dispatch(deleteCacheItem(payload));
                            dispatch(clearDeleted(clear));
                        } else {
                            dbg(`Interval: DeletePendings -> ${key} -- ${id} -- No action defined`);
                        }
                    });
                });
            }
        }, recheckTimer);
        return () => {
            //dbg("CLEARING INTERVAL")
            clearInterval(interval);
        };
    }, [
        uploadTime, uploadsLoading,
        jobTime, jobsLoading,
        projectTime, projectsLoading,
        memberTime, membersLoading,
        modelTime, modelsLoading,
        apikeyTime, apikeysLoading,
        activityTime, activitiesLoading,
        feedTime, feedsLoading,
        embeddingTime, embeddingsLoading,
        globalTimings,
        ws
    ]);
    return children
}
