import React, { useState } from "react";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "DangerZone.js");


const DangerZone = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDangerZone = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleDangerZone} className="red">
        {isOpen ? "Close Danger Zone" : "Open Danger Zone"}
      </button>
      {isOpen && (
        <div
          className="mt-48"
          style={{
            padding: "32px",
            border: "1px solid red",
            borderRadius: "16px",
          }}
        >
          <h3>Danger Zone: Be careful</h3>
          {/* Render passed-in child elements */}
          <div className="mt-24">{children}</div>
        </div>
      )}
    </div>
  );
};

export default DangerZone;
