import {neuralApi} from "../NeuralApi/neuralApiReducer";
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import {getApiDomain} from "../../components/config";
import {mosaicApi} from "./mosaicApiReducer";
import createLogger from "../../utils/createLogger";
import {lf} from "../../utils/createLogger";

//import {Logger, LoggerFactory} from "lines-logger";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "redux/timing.js");
//var loggerFactory = new LoggerFactory();
//var logger = loggerFactory.getLogger("timing.js")
// Alternative logger use -- easier to read, but you have to call it with extra parens:
// logger.log("fubar")() -- so that the line number is right on the console (e.g. it gets called in place)

var logger = lf.getLogger("timing.js")

const epoch = moment().toISOString() //'1970-01-01').toISOString();

//
// FIXME: We dont' really use the timings stuff anymore except for the selectGlobalTimings selector (that doesn't
// use this slice, but collects data from the other slices.
//
/*
 *   Timings -- this keeps track of the last time we got an update from a given collection on the front end.
 *   For each collection we keep four values: attempt, last, actual and last results named by the collection:
 *   e.g. jobAttemptTime, jobLastEmptyAttemptTime, jobTime (the last time we got results), and jobLastResults.
 *
 *   Every time we call one of the get-all functions, we call the setter (setJob, etc) with the timestamp
 *   just before we make the call -- this is saved as an attempt (e.g. jobLastAttemptTime), otherwise we copy
 *   it to the last missed attempt (jobLastEmptyAttemptTime.
 *
 *   When the calling component calls into one of the get-alls, it pulls the last successful time (jobTime)
 *   to pass in with the &since flag -- featrix will look in that collection for anything that has changed since that
 *   time and only send those results.
 *
 *   Right now, this is all done form the component BaseInformation which keeps all of this subscribed.  I'm sure
 *   when we have a full UI team, they will have a way better pattern... but for now :)
 */
const getInitialState = () => {
    const areas = ["job", "project", "upload", "embedding", "model", "apikey", "prediction", "activity", "feed", "organization", "member"];
    // We list these out so the linter can stop complaining when we reference them in useSelector
    let initialState = {
        jobTime: moment().toISOString() ,
        projectTime: moment().toISOString() ,
        uploadTime: moment().toISOString() ,
        embeddingTime: moment().toISOString() ,
        modelTime: moment().toISOString() ,
        apikeyTime: moment().toISOString() ,
        predictionTime: moment().toISOString() ,
        activityTime: moment().toISOString() ,
        organizationTime: moment().toISOString(),
        feedTime: moment().toISOString(),
        memberTime: moment().toISOString() ,
        timingsResult: {},
    };
    areas.forEach((item) => {
        initialState[`${item}AttemptTime`] = initialState[`${item}Time`] ;
        initialState[`${item}LastEmptyAttemptTime`] = initialState[`${item}Time`]
        initialState[`${item}LastResults`] = [];
    });
    dbg('InitialState', initialState)
    return initialState;
}

const checkForUpdate = (field) => (state, action) => {
        let timeField = `${field}Time`, atField = `${field}AttemptTime`, lastAtField = `${field}LastEmptyAttemptTime`, lastResultsField = `${field}LastResults`;

    // logger.log(`CheckForUpdate: ${field}`)();
    // logger.log("Another try state {}", state)();
    dbg(`CheckForUpdate: ${field}: State for this timing ${state[timeField]}, attempt ${state[atField]}, last ${state[lastAtField]}`);

    /* 
     * The caller will set the attempt time each time with useDispatch.  When the query returns, we are subscribed
     * to it and check to see if there are any responses.  If there are, we save the timer and the responses:
     * - the timer provides us with the time of the last update, so we can ask Featrix API to send us any
     *      changed objects since that last point (if we got an original list at 9:00, and ask at 9:10, it only sends 
     *      us objects that have been updated after 9:00)
     * - We can inspect, if needed, the objects that
     * allowing two things: the next call can use the last time
     */
    state[`${field}Time`] = state[`${field}AttemptTime`];
    if (action.payload.length) {
        dbg(`CheckForUpdate: ${field}: results ${action.payload.length}, setting last results...`)
        state[`${field}LastResults`] = [...action.payload];
    } else {
        dbg(`CheckForUpdate: ${field}:  Setting last empty attempt`)
        state[`${field}LastEmptyAttemptTime`] = state[`${field}AttemptTime`];
    }
}

const timingSetter = (field) => (state, action) => {
    dbg(`timingSetter: Setting ${field}AttemptTime to ${action.payload}`);
    state[`${field}AttemptTime`] = action.payload;
}

const clearTimings = (state, action) => {
    Object.keys(state).forEach((key) => {
       state[key] = moment("1970-01-01").toISOString()
    })
    state.timingsResults = {}
}

const fetchTimings = createAsyncThunk(
    'timings/updates',
    async (data, thunkAPI) => {
        dbg("FETCH TIMINGS". data)
        dbg(data);
        const response = await fetch(`${getApiDomain()}/mosaic/organizations/updates`,
            {
                method: 'POST',
                headers: {"Content-Type": "application/json",},
                body: JSON.stringify(data)
            });
        const result = await response.json();

        ////  DEBUG
        let str = "";
        Object.keys(result).forEach(value => {
            if(result[value]) str += `${value}: true, `
        })
        if(str.length) dbg(`timingsRequest: ${str}`);
        else dbg(`timingsRequest: No Collections Out of Date`)
        //// DEBUG
        return result;
    },
)

const timingSlice = createSlice({
    name: 'timings',
    initialState: getInitialState(),
    reducers: {
        setJob: timingSetter('job'),
        setUpload: timingSetter('upload'),
        setProject: timingSetter('project'),
        setEmbedding: timingSetter('embedding'),
        setModel: timingSetter('model'),
        setApiKey: timingSetter('apikey'),
        setPrediction: timingSetter('prediction'),
        setActivity: timingSetter('activity'),
        setMember: timingSetter('member'),
        setFeed: timingSetter('feed'),
        setOrganization: timingSetter('organization'),
        clearAll: clearTimings,
    },
    extraReducers: builder => {
        builder.addCase(fetchTimings.fulfilled, (state, action) => {
            state.timingsResults = action.payload
        });
        builder.addMatcher(neuralApi.endpoints.uploads.matchFulfilled, checkForUpdate('upload'));
        builder.addMatcher(neuralApi.endpoints.jobs.matchFulfilled, checkForUpdate('job'));
        builder.addMatcher(neuralApi.endpoints.projects.matchFulfilled, checkForUpdate('project'));
        builder.addMatcher(mosaicApi.endpoints.members.matchFulfilled, checkForUpdate('members'));
        builder.addMatcher(mosaicApi.endpoints.apiKeys.matchFulfilled, checkForUpdate('apikey'));
        builder.addMatcher(neuralApi.endpoints.getAllEmbeddingSpaces.matchFulfilled, checkForUpdate('embedding'));
        builder.addMatcher(neuralApi.endpoints.models.matchFulfilled, checkForUpdate('model'));
        builder.addMatcher(mosaicApi.endpoints.getUserOrganizations.matchFulfilled, checkForUpdate('apikey'));
        builder.addMatcher(mosaicApi.endpoints.activities.matchFulfilled, checkForUpdate('activity'));
        builder.addMatcher(neuralApi.endpoints.feeds.matchFulfilled, checkForUpdate('feed'));
        //builder.addMatcher(neuralApi.endpoints.predictions.matchFulfilled, checkForUpdate('prediction'));
    }
});

const {actions, reducer} = timingSlice;

export const selectGlobalTimings = (state) => {
    //
    // Find the latest update time we have for each of our major collections and use that for the
    // the update request.
    //
    // made a mess of naming, this should be consistent so we don't need a map.
    const map = {'job_meta': 'jobs', 'project': 'projects', 'upload': 'uploads', 'members': 'users',
        'api_key': 'apiKeys', 'prediction': 'predictions', 'model': 'models', 'embedding_space': 'embeddings',
        'feed': 'feeds', 'activity': 'activity', 'organization': 'organizations'}
    let request = {};
    Object.keys(map).forEach(key => {
        let time;
        Object.values(state[map[key]].entities).forEach(entry => {
            let m = moment.utc(entry.updated_at);
            if(!time || time < m) {
                time = m;
            }
        })
        request[key] = time ? time.toISOString() : null;
    })
    return request;
}


export const {
    setUpload,
    setActivity,
    setApiKey,
    setEmbedding,
    setJob,
    setModel,
    setPrediction,
    setProject,
    setMember,
    setFeed,
    setOrganization,
    clearAll,
} = actions;
export {fetchTimings};
export default reducer;

