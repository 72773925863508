import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import createLogger from "../../utils/createLogger";
import moment from "moment/moment";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "predictionMapReducer.js");

const predictionAdapter = createEntityAdapter({
  selectId: (upload) => upload._id,
  sortComparer: (a, b) =>  moment(a.created_at) <= moment(b.created_at) ? -1 : 1
});

const addPredictions = (state, action) => {
        // transform array to an object
        if (isArray(action.payload)) {
            predictionAdapter.setMany(state, action.payload);
        } else {
            predictionAdapter.setOne(state, action.payload);
        }
}

const predictionsMapSlice = createSlice({
    name: 'predictionsMap',
    initialState: predictionAdapter.getInitialState(),
    reducers: {
         deletePredictionCacheItem: (state, action) => {
            predictionAdapter.removeOne(state, action.payload.id);
        },
        clearPredictions: (state, action) => {
              predictionAdapter.removeAll(state);
        },
        addPredictionToCache: (state, action) => {
             predictionAdapter.addOne(state, action.payload);
        }
    },
    extraReducers: builder => {
        // Anything that returns an Upload should be caught here -- if it just returns an Upload or [Upload, ..],
        // addUploads will take it.
        builder.addMatcher(neuralApi.endpoints.predictionsByModel.matchFulfilled, addPredictions);
        builder.addMatcher(neuralApi.endpoints.runFastPrediction.matchFulfilled, addPredictions);
    }
});


export const selectPredictions = (state) => state.predictions.entities
export const selectPredictionsIds = (state) => state.predictions.ids

export const selectPredictionsList  = createSelector(
    [selectPredictions, selectPredictionsIds],
    (predictions, ids) => ids.map(id => predictions[id]));

export const selectPredictionsByModel = modelId => createSelector(
    [selectPredictionsList],
    (predictions) => predictions.filter(p => p.model_id === modelId)
)
export const selectPredictionLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.predictions.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const {
    deletePredictionCacheItem,
    clearPredictions,
    addPredictionToCache
} = predictionsMapSlice.actions;

export default predictionsMapSlice.reducer;