import React, { useState } from "react";
import { userReducer } from "../../redux/MosaicApi/userReducer";
import { organizationReducer } from "../../redux/MosaicApi/organizationReducer";
import { projectReducer } from "../../redux/NeuralApi/projectReducer";
import { Banner } from "../Banner";
import spinner2 from "../../assets/pictures/spinner2.gif";
import createLogger from '../../utils/createLogger';

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, `ORganizationsDashboardView.js`);

 export function OrganizationsDashboardView() {
//    const { data: cachedOrganization } =
//      organizationReducer.endpoints.getUserCurrentOrganization.useQueryState(
//        "root",
//      );
//    dbg(cachedOrganization);

   return (
       <div
           style={{
               width: "90%",
               margin: "50px",
               paddingTop: "10px",
           }}
       >
           <h1>Welcome to Featrix</h1>
           "This is going to be fun"<br/><i>&mdash;Featrix co-founder</i>
           <div
               style={{
                   display: "flex",
                   alignItems: "center",
                   marginTop: "50px",
               }}
           >
               <div
                   style={{
                       textAlign: "left",
                   }}
               >
                <div
                    style={{
                        width: "95%",
                        border: "3px solid #1b1b26",
                        backgroundColor: "rgba(27, 27, 38, 0.5)",
                        padding: "32px",
                        borderRadius: "16px",
                        backgroundImage: `url(${spinner2})`,
                    }}
                >

                    <h3>Getting started: What should I do next?</h3>
                    <table className="gettingStartedPalette" cellSpacing={40}>
                        <tbody>
                            <tr>
                                <td><b>0. Open "my first project"</b>
                                </td>
                                <td><b>1. Load data</b>
                                </td>
                                <td>
                                    <b>2. Create a neural function</b>
                                </td>
                            </tr>
                            <tr>
                                <td>3. Run a prediction</td>
                                <td>Bonus: Invite a colleague</td>
                                <td>Bonus: Explore documentation</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                   {/* <h3
                       style={{
                           color: "var(--featrix-headline-text-color)",
                           fontSize: "2.5rem",
                           paddingBottom: "12px",
                           paddingTop: "14px"
                       }}
                   >Get Started In Four Easy Steps</h3>
                   <h3
                       style={{
                           color: "var(--featrix-headline-text-color)",
                           fontSize: "1.5rem",
                           paddingBottom: "8px"
                       }}
                   >
                       1. Create a project
                   </h3>
                   <p style={{ paddingBottom: "8px" }}>
                       Use the New Project button on the left.</p>
                   <h3
                       style={{
                           color: "var(--featrix-headline-text-color)",
                           fontSize: "1.5rem",
                           paddingBottom: "8px"
                       }}
                   >
                       2. Connect Your Data
                   </h3>
                   <p style={{ paddingBottom: "8px" }}>
                       Click the Connect New Data on your new project screen, and select Upload File Manually to upload a CSV datafile from your computer</p>

                   <h3
                       style={{
                           color: "var(--featrix-headline-text-color)",
                           fontSize: "1.5rem",
                           paddingBottom: "8px"
                       }}
                   >
                       3. Create a new Neural Function

                   </h3>
                   <p style={{ paddingBottom: "8px" }}>
                       Back on the project screen, create a neural function with the data you just uploaded</p>
                   <h3
                       style={{
                           color: "var(--featrix-headline-text-color)",
                           fontSize: "1.5rem",
                           paddingBottom: "8px"
                       }}
                   >
                       4. Run a prediction
                   </h3>
                   <p style={{ paddingBottom: "8px" }}>
                       Now run a prediction against the neural function you created</p> */}
               </div>
           </div>
       </div>
   );
 }
