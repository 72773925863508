import {neuralApi} from "../NeuralApi/neuralApiReducer";
import {mosaicApi} from "./mosaicApiReducer";

import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "activitiesMapReducer.js");


const activitiesAdapter = createEntityAdapter({
  selectId: (activity) => activity._id,
  sortComparer: (a, b) => moment(a.created_at) <= moment(b.created_at) ? -1 : 1
});

const getExtraState = () => {
    return {
        deletesPending: {
            projectDeleteIds: [],
            embeddingDeleteIds: [],
            modelDeleteIds: [],
            uploadDeleteIds: [],
            userDeleteIds: [],
            apikeyDeleteIds: [],
            feedDeleteIds: [],
        }
    }
}

const activityProcessor = (state, action) => {
    activitiesAdapter.setMany(state, action.payload);
    const deleteActivity = (state, activity) => {
        const {
            activity_type,
            impacted_project_id,
            impacted_embedding_space_id,
            impacted_model_id,
            impacted_upload_id,
            impacted_user_id,
            impacted_api_key_id
        } = activity;
        if (activity_type === "project.deleted") {
            state.deletesPending.projectDeleteIds.push(impacted_project_id);
        } else if (activity_type === "embedding-space.deleted") {
            state.deletesPending.embeddingDeleteIds.push(impacted_embedding_space_id);
        } else if (activity_type === "neural-function.deleted") {
            state.deletesPending.modelDeleteIds.push(impacted_model_id);
        } else if (activity_type === "upload.deleted") {
            state.deletesPending.uploadDeleteIds.push(impacted_upload_id);
        } else if (activity_type === "user.deleted") {
            state.deletesPending.userDeleteIds.push(impacted_user_id);
        } else if (activity_type === "api_key.deleted") {
            state.deletesPending.apikeyDeleteIds.push(impacted_api_key_id);
        }
    };
    action.payload.forEach(activity => {
        if (activity.activity_type.endsWith("deleted")) {
            deleteActivity(state, activity);
        }
    });
};

const activitiesMapSlice = createSlice({
    name: 'activitiesMap',
    initialState: activitiesAdapter.getInitialState(getExtraState()),
    reducers: {
        clearDeleted: (state, action) => {
            if(action.payload.id === 'all') {
                state.deletesPending = getExtraState()['deletesPending'];
            } else {
                state.deletesPending[`${action.payload.id}DeleteIds`] = [];
            }
        },
        clearActivities: (state, action) => {
              activitiesAdapter.removeAll(state);
        },
        addActivityToCache: (state, action) => { activitiesAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.activities.matchFulfilled,
            activityProcessor,

        );
        // Other matchers? Project.activities?
    }
});

export const selectActivities = (state) => state.activity.entities;
export const selectActivityIds = (state) => state.activity.ids

export const selectActivityList = (depth) => createSelector(
    [selectActivities, selectActivityIds],
    (activity, ids) =>
        (depth === undefined ? ids : ids.slice(-depth)).map(id => activity[id])
)


export const selectActivityById = (id) => createSelector(
    selectActivities,
    items => items[id]
)

const selectActivityByField = (fieldName, value, count) => (activities) => {

    let list = activities.filter(activity => activity[fieldName] === value);
    return count === undefined ? list : list.slice(-count);
}

// will return all of the activities for user, depth is how deep in full activities list not # of user activities
// count is for the user events -- depth left off means all of them, count is the same
export const selectActivityByUser = (id, count, depth) => createSelector(
    selectActivityList(depth),
    selectActivityByField("created_by", id, count))

export const selectActivityByImpactedUser = (id, count, depth) => createSelector(
    selectActivityList(depth),
    selectActivityByField("impacted_user_id", id, count))

export const selectActivityByImpactedProject = (id, count, depth) => createSelector(
    selectActivityList(depth),
        selectActivityByField("impacted_project_id", id, count))

export const selectActivityByImpactedEmbeddingSpace = (id, count, depth) => createSelector(
    selectActivityList(depth),
        selectActivityByField("impacted_embedding_space_id", id, count))

export const selectActivityByImpactedModel = (id, count, depth) => createSelector(
    selectActivityList(depth),
        selectActivityByField("impacted_model_id", id, count))

export const selectActivityByImpactedUpload = (id, count, depth) => createSelector(
    selectActivityList(depth),
        selectActivityByField("impacted_upload_id", id, count))

export const selectActivityByActivity = (name, count, depth) => createSelector(
    selectActivityList(depth),
        selectActivityByField("activity_type", name, count))


export const selectActivitiesSince = (aMoment, depth) =>
    createSelector(
    selectActivityList(depth),
    (itemsList) => itemsList.filter(item => moment(item.created_at) > aMoment)
)

export const selectPendingDeleteIds = createSelector(
    state => state.activity.deletesPending,
    deletesPending => deletesPending
);

export const selectActiviesLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.activity.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}


export const {clearDeleted, clearActivities, addActivityToCache} = activitiesMapSlice.actions;
export default activitiesMapSlice.reducer;