import SyntaxHighlighter from "react-syntax-highlighter";
import anOldHope from "react-syntax-highlighter/dist/esm/styles/hljs/an-old-hope";
import createLogger from "../utils/createLogger";

const DEBUG = true;
const { dbg, enter, leave, logError } = createLogger(DEBUG, "CodeHighlighter.js");


export const DefaultThemedCodeHighlighter = ({ code, language, children, ...rest }) => (
    <SyntaxHighlighter
        language={language ?? "python"}
        style={anOldHope}
        customStyle={{
            background: "var(--featrix-syntax-highlight-bg-color)",
        }}
        {...rest}
    >
        {code ?? children}
    </SyntaxHighlighter>
);
