import { mosaicApi } from "./mosaicApiReducer";
import createLogger from "../../utils/createLogger";

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "userReducer.js");

export const userReducer = mosaicApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query({
            query: () => "/users/me/",
            providesTags: ["User"],
        }),

        updateCurrentOrgId: build.mutation({
            query: (currentOrgId) => ({
                url: `/users/me/current_organization?org_id=${currentOrgId}`,
                method: "POST",
                body: currentOrgId,
            }),
            transformErrorResponse: (response, meta, arg) => response.status,
            invalidatesTags: ["User", "Organization", "Activity", "Key", "Project", "Job", "Model", "Field", "Uploads", "Embedding", "Distance", "Histogram", "Database", "Feed"],
        }),
    }),

    overrideExisting: true,
});

// Our methods are no longer (or ```sometimes no longer```) generated now that we're using code splitting (this is a precaution made by the redux team)
// So, we need to manually name our hooks in the export:
// Define the hooks created in your reducer using the naming convention:

//      for GET requests: `use + <name-of-endpoint's-reducer-path> + Query`
//      for POST/PATCH/DELETE: `use + <name-of-endpoint's-reducer-path> + Mutation`

export const {
    useGetCurrentUserQuery,
    useUpdateCurrentOrgIdMutation } =
userReducer;
