// import spinner from "../assets/pictures/spinner.gif";
// import spinner2 from "../assets/pictures/spinner2.gif";

// export const Spinner = () => {
//     return (
//             <img src={spinner} width="24" alt="" style={{marginRight: "16px"}}/>
//     );
// };

export const Spinner = () => {
    return <svg className="spinner-chasing" width="32" height="32" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45"/>
  </svg>
  

    // <svg width="200" height="200" viewBox="0 0 100 100">
    //     <circle cx="50" cy="50" r="45" stroke="#e6e6e6" stroke-width="10" fill="none" />
    //     <circle cx="50" cy="50" r="45" stroke="#3498db" stroke-width="10" fill="none" 
    //             stroke-dasharray="283" stroke-dashoffset="283">
    //       <animate attributeName="stroke-dashoffset" from="283" to="0" dur="2s" repeatCount="indefinite" />
    //     </circle>
    //   </svg>

    // return (
    //     <div style={{"zoom": 0.33}}>
    //         <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    //     </div>
    // );
}