import {neuralApi} from "./neuralApiReducer";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {isArray} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "jobsMapReducer.js");



const jobsAdapter = createEntityAdapter({
  selectId: (job) => job._id,
  sortComparer: (a, b) => moment(a.created_at) <= moment(b.created_at) ? -1 : 1
});


const jobsMapSlice = createSlice({
    name: 'jobsMap',
    initialState: jobsAdapter.getInitialState(),
    reducers: {
         deleteJobCacheItem: (state, action) => {
            jobsAdapter.removeOne(state, action.payload.id);
        },
        clearJobs: (state, action) => {
              jobsAdapter.removeAll(state);
        },
        addJobToCache: (state, action) => { jobsAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(neuralApi.endpoints.jobs.matchFulfilled,
            (state, action) =>
                    jobsAdapter.setMany(state, action.payload)
        );
        // Other matchers? Project.jobs?
    }
});

export const selectJobs = (state) => { dbg(state); return state.jobs.entities}
export const selectJobList = (state) => Object.values(state.jobs.entities);

export const selectJobIds = (state) => state.jobs.ids

export const selectJobById = (id) => createSelector(
    selectJobs,
    items => items[id]
)

export const selectOrderedJobs = createSelector(
    [selectJobs, selectJobIds],
    (items, ids) => {
        let jobs = [];
        ids.forEach(id => jobs.push(items[id]))
        return jobs;
    }
)

// This will always be the smallest list in the project collection
export const selectActiveJobs =
    createSelector(selectOrderedJobs, items => items.filter(item => !item.finished));

export const selectActiveJobById = (jobId) =>
    createSelector(
        selectOrderedJobs,
            items => items.find(item => item._id === jobId && !item.finished)
    );
export const selectActiveUploadProcessingJob = (uploadId) =>
    createSelector(
        selectJobs,
            items => Object.values(items).find(item => item.request_args.upload_id === uploadId && !item.finished && item.job_type === "process-uploaded-data")
    );
export const selectUploadProcessorJob = (uploadId) =>
    createSelector(
        selectJobs,
            items => Object.values(items).find(item => item.request_args.upload_id === uploadId && item.job_type === "process-uploaded-data")
    );

export const selectProjectJobs = (projectId) => 
    createSelector(selectOrderedJobs, items => items.filter(item => item.project_id === projectId))
// Select all project jobs -- for just the active, use selectActiveJobs(projectid) 
export const selectActiveProjectJobs = (projectId) =>
    createSelector(selectActiveJobs, (items) => { dbg("selectActiveProjectJobs", items); return items.filter(item => projectId === item.project_id) })

export const selectModelJobs = (modelId) =>
    createSelector(selectOrderedJobs, items => items.filter(item => item.model_id === modelId));
// Select all project jobs -- for just the active, use selectActiveJobs(projectid) 
export const selectActiveModelJobs = (modelId) =>
    createSelector(selectActiveJobs, items => items.filter(item => item.model_id === modelId))

// With embedding jobs, the optional second arg is a bool - set to true if you want to exclude jobs that reference a model as well
// (e.g.: embedding creates for instance)
// selectEmbeddingJobs(embeddingId) -- gets all jobs that reference embedding space id
// selectEmbeddingJobs(embeddingId, true) -- get all jobs that are to that embedding but there is no model is ref'ed in the job

export const selectEmbeddingJobs = (embeddingId, excludeModelJobs = false) =>
    createSelector(selectOrderedJobs,
            items => items.filter(item => item.embedding_id === embeddingId && (!excludeModelJobs || (excludeModelJobs && !item.model_id))));
// Select all project jobs -- for just the active, use selectActiveJobs(projectid)
export const selectActiveEmbeddingJobs = (embeddingId, excludeModelJobs = false) =>
    createSelector(
        selectActiveJobs,
            items => items.filter(item => item.embedding_id === embeddingId && (!excludeModelJobs || (excludeModelJobs && !item.model_id))))
;

export const selectJobsByJobType = (jobType) =>
    createSelector(selectOrderedJobs, items => items.filter(item => item.job_type === jobType));

export const selectActiveJobsByJobType = (jobType) =>
    createSelector(selectActiveJobs, items => items.filter(item => item.job_type === jobType));

export const selectJobsSince = (aMoment) =>
    createSelector(
    [selectJobs, selectJobIds],
    (items, ids) => {
        let jobs = [];
        ids.forEach(id => {
            if (moment(items[id].created_at) >= aMoment) jobs.push(items[id]);
        })
        return jobs;
    }
)
export const selectActiveJobsSince = (aMoment) =>
    createSelector(
    [selectJobs, selectJobIds],
    (items, ids) => {
        let jobs = [];
        ids.forEach(id => {
            if (items[id].finished && moment(items[id].created_at) >= aMoment) jobs.push(items[id]);
        })
        return jobs;
    }
)
export const selectJobsLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.jobs.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const { deleteJobCacheItem, addJobToCache, clearJobs } = jobsMapSlice.actions;

export default jobsMapSlice.reducer;