import {createSlice, createAsyncThunk, createEntityAdapter, createSelector} from '@reduxjs/toolkit';
import {mosaicApi} from "./mosaicApiReducer"
import {isArray} from "lodash";
import createLogger from "../../utils/createLogger";
import Moment from "moment";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "usersMapReducer.js");

const usersAdapter = createEntityAdapter({
    selectId: (user) => user._id,
    sortComparer: (a, b) => `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`),
});


const membersMapSlice = createSlice({
    name: 'membersMap',
    initialState: usersAdapter.getInitialState(),
    reducers: {
         deleteUserCacheItem: (state, action) => {
            usersAdapter.removeOne(state, action.payload.id);
        },
        clearUsers: (state, action) => {
              usersAdapter.removeAll(state);
        },
        addUserToCache: (state, action) => { usersAdapter.setOne(state, action.payload) }
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.members.matchFulfilled,
            (state, action) => {
                if (isArray(action.payload)) {
                    dbg("setMany", action.payload);
                    usersAdapter.setMany(state, action.payload);
                } else {
                    dbg("setOne", action.payload);
                    usersAdapter.setOne(state, action.payload);
                }
            }
        );
    }
});

export const selectUsers = (state) => state.users.entities
export const selectUserIds = (state) => state.users.ids

export const selectUserList = createSelector(
    [selectUsers, selectUserIds],
    (users, ids) => ids.map(id => users[id]));

export const selectUsersLatestUpdate = (state) => {
    let latest = null;
    Object.values(state.users.entities).forEach(item => {
        if (!latest || Moment.utc(item.updated_at) > Moment.utc(latest)) {
            latest = item.updated_at;
        }
    });
    return latest;
}

export const { deleteUserCacheItem, clearUsers, addUserToCache } = membersMapSlice.actions;

export default membersMapSlice.reducer;