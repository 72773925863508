import { useParams } from "react-router-dom";
// redux
import { useActivitiesQuery } from "../../redux/MosaicApi/activityReducer";
import { useSelector } from "react-redux";
import moment from "moment";
//---------TODO: We want the name & email of the user that posts the activity - not their _id
// import { useGetOrganizationMembersQuery } from "../../redux/MosaicApi/organizationReducer";
import createLogger from '../../utils/createLogger';

const DEBUG = true;
const {dbg, enter, leave, logError} = createLogger(DEBUG, `OrganizationActivity.js`);



function JsonToReact({ json }) {
    const renderJson = (node) => {
        if (typeof node === 'object' && !Array.isArray(node) && node !== null) {
            return (
                <ul>
                    {Object.entries(node).map(([key, value]) => (
                        <li key={key}>
                            <strong>{key}:</strong> {renderJson(value)}
                        </li>
                    ))}
                </ul>
            );
        } else if (Array.isArray(node)) {
            return (
                <ul>
                    {node.map((item, index) => (
                        <li key={index}>{renderJson(item)}</li>
                    ))}
                </ul>
            );
        } else {
            return <span>{node}</span>;
        }
    };

    return <div>{renderJson(json)}</div>;
}

export const ActivityTableRows = ({ activitiesData }) => {
    const users = useSelector((state) => state.users.entities);
    const { data: activities = [] } = useActivitiesQuery();

    return (
        <div className="mt-4" style={{ marginTop: "32px" }}>
            <div className="resource-container">
                <table className="activityTable" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>When (local timezone)</th>
                            <th>User</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    {activities && users ? (
                        <>
                            <tbody>
                                {activities?.map((activity, idx) => (
                                    <tr key={activity._id}>
                                        <td>{activity.activity_type}</td>
                                        <td>{moment.utc(activity.created_at).local().format()}</td>
                                        <td>
                                            {users[activity.created_by].first_name} ({users[activity.created_by].email})
                                        </td>
                                        <td>
                                            <div>
                                                <JsonToReact json={activity.details} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </>
                    ) : (
                        <tbody></tbody>
                    )}
                </table>
            </div>
            {activities ? (
                <div style={{marginTop: "32px"}}>
                    <p>{"The most recent 100 events are shown."}</p>
                </div>) : null
            }
        </div>
    );
};

export const OrganizationActivityTab = () => {
    return (
        <div className="pt-36 fit-width mt-16">
            <section className="resource resource-members">
                <span className="resource-section-label">ACTIVITY</span>
                    <ActivityTableRows />
            </section>
        </div>
    );
};
