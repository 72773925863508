import {createSlice} from '@reduxjs/toolkit';
import createLogger from "../../utils/createLogger";
import {mosaicApi} from "./mosaicApiReducer";

//import {Logger, LoggerFactory} from "lines-logger";

const DEBUG = false;
const {dbg, enter, leave, logError} = createLogger(DEBUG, "redux/admin.js");


const getInitialState = () => {
    return {
        user: null,
        organization: null,
    };
}


const setCurrentUserAction = (state, action) => {
    state.user = action.payload;
}

const setCurrentOrganizationAction = (state, action) => {
    state.organization = action.payload;
}

const clearAdminAction = (state, action) => {
    state.admin = getInitialState();
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: getInitialState(),
    reducers: {
        setCurrentUser: setCurrentUserAction,
        setCurrentOrganization: setCurrentOrganizationAction,
        clearAdmin: clearAdminAction,
    },
    extraReducers: builder => {
        builder.addMatcher(mosaicApi.endpoints.getUserCurrentOrganization.matchFulfilled, (state, action) => { state.organization = action.payload });
        builder.addMatcher(mosaicApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => { state.user = action.payload }
        );
    }
});

const {actions, reducer} = adminSlice;

export const selectCurrentUser = (state) => state.admin.user
export const selectCurrentOrg = (state) => state.admin.organization

export const {
    setCurrentUser,
    setCurrentOrganization,
    clearAdmin,
} = actions;
export default reducer;

